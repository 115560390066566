// src/analytics/models/eventDataModel.ts

export interface EventData {
  session_id: string; // Unique ID for the visit/session TODO: SESSION
  timestamp: string; // Timestamp of the event
  user: UserData; // User-related information
  page: PageData; // Page-related information
  interaction: InteractionData; // Interaction-related information
  product: ProductData; // Product or feature-related information
  // marketing: MarketingData;        // Marketing-related information
  event_payload: EventPayload; // Additional custom data specific to the event
  // errors: Record<string, any>;     // Any errors associated with the event (if applicable)
  // performance: PerceivedPerformance; // Perceived performance data

  page_visit_id: string; // Unique ID for each page visit (all events triggered on this page will have the same visit ID)
}

export interface UserData {
  user_id: string | null; // User ID from the _store.getState()
  user_auth: true | false; // User authentication status
  user_global_id: string | null; // Global user ID
  device: any; // Any because it uses ClientDetection.getDeviceData()
  location: LocationData; // Location-related information
}

export interface LocationData {
  ip_address: string; // User's IP address
  city: string; // City name
  country: string; // Country name
  region: string; // Region or state name
}

export interface PageData {
  url: string; // URL of the page where the event occurred
  title: string; // Title of the page
  referrer: string; // Referrer URL (previous page)
  language: string; // Language of the page (or user's browser locale)
  viewport: { width: number; height: number }; // Viewport dimensions
  scroll_depth: string; // Scroll depth (e.g., '50%')
  // visit_history: string[];         // History of visited pages in the session
}

export interface InteractionData {
  interaction_type: string; // Type of interaction (e.g., 'click', 'hover', 'scroll')
  element_id: string; // ID of the interacted element (if available)
  element_type: string; // Type of the interacted element (e.g., 'button', 'link', 'image')
  element_data_source: string; // Data source of the element (if available, e.g., 'data-tracking' attribute)
  element_text: string; // Text content of the interacted element (if applicable)
  element_position: string; // Position of the element in the DOM (if relevant)
  element_state: string; // State of the element (e.g., 'enabled', 'disabled')

  // percentage x and y of the click event relative to the whole page
  click_position: { x: number; y: number } | null;

  // touch coordinates
  touch_coordinates?: { from: { x: number; y: number }; to: { x: number; y: number } };

  // containing category of the element (e.g., 'hot games', 'new games', 'popular games')
  // can be null if no category is found in the element or parent elements
  element_category?: string | null;

  // element label
  element_label: string;
}

export interface ProductData {
  feature_used: string; // Feature or product being used (e.g., 'momentum-turbo-wins')
  feature_category: FeatureCategory; // Category of the feature (e.g., 'Momentum')
  feature_subcategory: FeatureSubcategory; // Subcategory of the feature (e.g., 'InGameNotification')
}

export interface PerceivedPerformance {
  time_taken: string; // Time a task or interaction (e.g., '120ms')
  render_time: string; // Time for rendering (e.g., '80ms')
}

export interface MarketingData {
  source: string; // Source of the traffic (e.g., 'google', 'facebook')
  channel: string; // Marketing channel (e.g., 'organic', 'paid')
}

export interface EventPayload {
  [key: string]: any; // Custom event-specific data; anything. maybe all _store.getState()
}

// Enum for feature category
export enum FeatureCategory {
  MomentumTurboCash = 'Momentum Turbo Cash',
  MomentumTurboWins = 'Momentum Turbo Wins',
  Promo = 'Promo',
  Casino = 'Casino',
  MainPage = 'Main Page',
  Bets = 'Bets',
  Wallet = 'Wallet',
  SearchBar = 'Search Bar',
  BetsRecommendation = 'Bets Recommendation',
  System = 'System',

  Unspecified = 'Unspecified',
}

// Enum for feature subcategory
export enum FeatureSubcategory {
  InGameNotification = 'InGameNotification',
  SystemEvent = 'SystemEvent',
  LobbyCard = 'LobbyCard',
  LpPromoCarousel = 'LPPromoCarousel',
  LpStories = 'LPStories',
  SearchBar = 'Search Bar',
  SystemError = 'SystemError',
  Betslip = 'Betslip',
  Page = 'Page',

  PlaceTicket = 'Place Ticket',

  OtherRecomandation = 'OtherRecomandation',
  OtherRecomandations = 'OtherRecomandations',

  NotApplicable = 'N/A',

  Unspecified = 'Unspecified',
}
