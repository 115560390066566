import * as actionTypes from '../actions/actionTypes.js';

export function initiateBridgerPayDeposit(data: any): any {
  return {
    type: actionTypes.deposit.INITIATE_BRIDGER_PAY_DEPOSIT,
    data,
  };
}

export function bridgerPayInitiatedSuccess(data: any): any {
  return {
    type: actionTypes.deposit.INITIATE_BRIDGER_PAY_DEPOSIT_SUCCESS,
    data,
  };
}

export function bridgerPayInitiatedFailed(): any {
  return {
    type: actionTypes.deposit.INITIATE_BRIDGER_PAY_DEPOSIT_FAILED,
  };
}

export function bridgerPayDepositResponse(data: any): any {
  return {
    type: actionTypes.deposit.BRIDGER_PAY_DEPOSIT_RESPONSE,
    data,
  };
}

export function initiateBridgerApplePayDeposit(data: any): any {
  return {
    type: actionTypes.deposit.INITIATE_BRIDGER_APPLE_PAY_DEPOSIT,
    data,
  };
}

export function updateBridgerApplePayDeposit(data: any): any {
  return {
    type: actionTypes.deposit.UPDATE_BRIDGER_APPLE_PAY_DEPOSIT,
    data,
  };
}

export function bridgerApplePayInitiatedSuccess(data: any): any {
  return {
    type: actionTypes.deposit.INITIATE_BRIDGER_APPLE_PAY_DEPOSIT_SUCCESS,
    data,
  };
}

export function bridgerApplePayInitiatedFailed(): any {
  return {
    type: actionTypes.deposit.INITIATE_BRIDGER_APPLE_PAY_DEPOSIT_FAILED,
  };
}

export function bridgerApplePayDepositResponse(data: any): any {
  return {
    type: actionTypes.deposit.BRIDGER_APPLE_PAY_DEPOSIT_RESPONSE,
    data,
  };
}

export function finishBridgerPayDeposit(data: any): any {
  return {
    type: actionTypes.deposit.FINISH_BRIDGER_PAY_DEPOSIT,
    data,
  };
}

export const oktoCreatePaymentCode = (amount: number, bonusId: number | null) => {
  return {
    type: actionTypes.deposit.OKTO_CREATE_PAYMENT_CODE,
    amount: amount,
    bonusId: bonusId,
  };
};

export const oktoReceivedPaymentCode = (data: any) => {
  return {
    type: actionTypes.deposit.OKTO_RECEIVED_PAYMENT_CODE,
    data: data,
  };
};

export const oktoGetCustomer = () => {
  return {
    type: actionTypes.deposit.OKTO_GET_CUSTOMER,
  };
};

export const oktoReceivedCustomer = (data: any) => {
  return {
    type: actionTypes.deposit.OKTO_RECEIVED_CUSTOMER,
    data: data,
  };
};

export const oktoReset = () => {
  return {
    type: actionTypes.deposit.OKTO_RESET,
  };
};

export const aircashGetPaymentLink = (amount: number, bonusId: number | null) => {
  return {
    type: actionTypes.deposit.AIRCASH_REQUEST_PAYMENT_LINK,
    amount: amount,
    bonusId: bonusId,
  };
};

export const receivedAircashPaymentLink = (data: any) => {
  return {
    type: actionTypes.deposit.AIRCASH_RECEIVED_PAYMENT_LINK,
    response: data,
  };
};

export const fetchPlayerPaymentMethods = () => {
  return {
    type: actionTypes.deposit.FETCH_PLAYER_PAYMENT_METHODS,
  };
};

export const receivedPlayerPaymentMethods = (data: any) => {
  return {
    type: actionTypes.deposit.RECEIVED_PLAYER_PAYMENT_METHODS,
    data,
  };
};

export const resetDeposit = () => {
  return {
    type: actionTypes.deposit.RESET,
  };
};

export const initiateBetLionDeposit= (data:{amount: any, bonusId:any, channelId: string, alternateMsisdn?: string|null}) => {
    return {
        type: actionTypes.deposit.INITIATE_BETLION_DEPOSIT,
        data
    };
};

export const betLionDepositInitiatedSuccess = (data: any) => {
    return {
        type: actionTypes.deposit.INITIATE_BETLION_DEPOSIT_SUCCESS,
        data,
    };
};

export const betLionDepositInitiatedFailed = () => {
  return {
    type: actionTypes.deposit.INITIATE_BETLION_DEPOSIT_FAILED,
  };
};

export const betLionDepositInitiatedPending = () => {
    return {
        type: actionTypes.deposit.INITIATE_BETLION_DEPOSIT_PENDING,
    };
};

export const betLionGetChannels = () => {
    return {
        type: actionTypes.deposit.BETLION_GET_CHANNELS,
    };
};
export const betLionReceivedChannels = (data: any) => {
    return {
        type: actionTypes.deposit.BETLION_RECEIVED_CHANNELS,
        data,
    };
};

export const betLionReceivedEkwanza = (paymentCode:string) => {

    return {
        type: actionTypes.deposit.BETLION_RECEIVED_EKWANZA,
        paymentCode
    };
};

export const betLionReceivedPayPay= (url: string, qrCode:any) => {
    return {
        type: actionTypes.deposit.BETLION_RECEIVED_PAYPAY,
        url,
        qrCode
    };
};

export const betLionGetAlternativePhoneNumber = () => {
    return {
        type: actionTypes.deposit.BETLION_GET_ALTERNATIVE_PHONE_NUMBER,
    };
};

export const betLionReceivedAlternativePhoneNumber = (alternativePhoneNumber: any) => {
    return {
        type: actionTypes.deposit.BETLION_RECEIVED_ALTERNATIVE_PHONE_NUMBER,
        alternativePhoneNumber,
    };
};