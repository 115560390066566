import { FeatureCategory, FeatureSubcategory } from '../types/eventData';
import { logEvent } from './logEvent';

// Helper function for logging the place ticket event
export const logPlaceTicketEvent = (featureUsed: string, betsSlip: any, ticketReference: string) => {
  try {
    logEvent(
      null,
      {
        feature_used: featureUsed,
        feature_category: FeatureCategory.Bets,
        feature_subcategory: FeatureSubcategory.PlaceTicket,
      },
      {
        ticket_details: {
          ticket_reference: ticketReference,
          ...getTicketDetails(betsSlip),
        },
      },
    );
  } catch (error) {
    console.error('Error logging event:', error);
  }
};

const getTicketDetails = (betsSlip: any) => {
  const currentTicket = betsSlip.tickets[betsSlip.currentTicket];
  const mapMatch = (match: any) =>
    ({
      mType: match?.match.mType,
      idSport: match?.idSport,
      idMatch: match?.idMatch,
      idBet: match?.idBet,
      idMb: match?.idMb,
      ...(match?.brmId && {
        brmId: match.brmId,
        brmMotivations: match.brmMotivations,
      }),
    }) as const;

  const { prematch, live } = currentTicket;

  return {
    prematch: prematch.selected.map(mapMatch),
    live: live.selected.map(mapMatch),
  };
};
