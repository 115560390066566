import styled from 'styled-components';
import { store, useAppSelector } from '@/store';
import { DataElementContext } from '@/page-components/common/DataElementContext';
import axios from 'axios';
import Hls from 'hls.js';
import React, { useEffect, useRef, useState } from 'react';
import urlonStringify from '@/utils/urlon';
import { forEach } from 'lodash';
import { storedRouter } from '@/routes';
import { stringToSlug } from '@/utils/gameIdsToObjects';

const SCROLL_DIRECTION = {
  UP: 'up',
  DOWN: 'down',
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

type VideoReelProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const useDataSource = (sourceId: string) => {
  const authenticationToken = useAppSelector((state) => state.authentication.access_token);
  const [state, setState] = React.useState<{ data?: any } | null>(null);

  React.useEffect(() => {
    axios
      .get(`${window.config.dataSourceApiUrl}/resolve/sources`, {
        headers: {
          Authorization: 'Bearer ' + authenticationToken,
        },
        params: {
          q: urlonStringify({ ids: [sourceId] }),
        },
      })
      .then((response) => {
        setState(response.data[sourceId]);
      })
      .catch(console.error);
  }, []);

  return state;
};

const VideoReel = (componentProps: VideoReelProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const reelRef = useRef(null);
  const sectionsRef = useRef<(HTMLElement | null)[]>([]);
  const [videos, setVideos] = useState([0, 1, 2]); // Track current and preloaded video indexes
  const [currentIndex, setCurrentIndex] = useState(0); // Track the index of the current video
  const [scrollDirection, setScrollDirection] = useState<
    typeof SCROLL_DIRECTION.UP | typeof SCROLL_DIRECTION.DOWN | null
  >(null); // Track scroll direction
  const dataResponse = useDataSource('video-reels-1');
  const videoSources = dataResponse?.data || [];
  const lastScrollY = useRef(0); // Store last scroll position
  const observer = useRef<IntersectionObserver | null>(null);
  const isMuted = useRef(true);
  const startingIndex = useRef(0);
  const isScrolledToPosition = useRef(false);
  const isInitialLoad = useRef(true); // Flag to prevent premature URL hash updates
  const viewPortHeight = window.innerHeight - 56;

  // get url param hash
  const urlHash = window.location.hash;
  const urlHashGameId = urlHash.replace('#', '');

  const openGame = (index: number) => {
    const gameId = videoSources[index]?.gameId;
    const gameName = stringToSlug(videoSources[index]?.name);
    // console.log('Open game:', gameId);
    if (storedRouter?.navigate) {
      storedRouter.navigate('/video-reels/' + gameId + '/' + gameName);
      window.dispatchEvent(new PopStateEvent('popstate'));
      return;
    }
  };

  const handleVideoPlayback = () => {
    sectionsRef.current.forEach((section, i) => {
      const videoHeader = section?.querySelector('.video-header') as HTMLVideoElement;
      const video = section?.querySelector('video.video') as HTMLVideoElement;
      if (video) {
        if (i === currentIndex) {
          // Play the current video with sound
          toggleSoundVideo(video, isMuted.current);
          videoHeader?.play();
          video.play().catch((error: any) => {
            if (error.name === 'NotAllowedError') {
              console.warn('Autoplay blocked, user interaction required.');
            } else {
              console.error('Play failed:', error);
            }
          });
        } else if (videos.includes(i)) {
          toggleSoundVideo(video, true, false);
          videoHeader.currentTime = 0;
          videoHeader?.pause();
          video.currentTime = 0;
          video.pause();
          // video.play().catch((error: any) => console.error('Preload play error:', error));
        } else {
          videoHeader.currentTime = 0;
          videoHeader?.pause();
          video.currentTime = 0;
          video.pause();
        }
      }
    });
  };

  // this is a fix for Safari address bar issue on iOS
  const setReelHeight = () => {
    const reelElement = document.getElementsByClassName('reel')[0];
    if (reelElement) {
      (reelElement as HTMLElement).style.height = viewPortHeight + 'px';

      const reelOverlayElement = document.getElementsByClassName('reel-overlay-element');
      forEach(reelOverlayElement, (el) => {
        (el as HTMLElement).style.height = viewPortHeight + 'px';
      });
    }
  };

  const updateHashDirectly = (hash: any) => {
    if (window.location.hash !== `#${hash}`) {
      // Temporarily remove the hash to ensure change is detected
      window.location.replace(`${window.location.href.split('#')[0]}#${hash}`);
      
      // Manually dispatch the hashchange event
      setTimeout(() => {
        window.dispatchEvent(new HashChangeEvent('hashchange'));
      }, 50);
    }
  };
  

  const setSpawnPoint = () => {
    const reelElement = document.getElementsByClassName('reel')[0];
    const targetSection = sectionsRef.current[startingIndex.current];
  
    if (reelElement && targetSection) {
      // console.log('Scrolling to:', viewPortHeight * startingIndex.current);
      reelElement.scrollTop = viewPortHeight * startingIndex.current;
      isScrolledToPosition.current = true;
      isInitialLoad.current = false; // Mark initial load as complete
    } else if (!isScrolledToPosition.current) {
      // Retry if the target section is not available yet
      // console.log('Target section not found or reel element is missing, retrying...');
      setTimeout(setSpawnPoint, 100); // Retry after a short delay
    }
  };
  

  const addVideoSection = (index: number) => {
    if (sectionsRef.current[index] || !videoSources[index]) return;

    const reelElement = reelRef.current;
    const section = document.createElement('section');
    section.className = 'snap';
    sectionsRef.current[index] = section;

    // @todo: refactor this to use styled-components
    const videoContainer = document.createElement('div');
    videoContainer.className = 'video-container';
    videoContainer.style.backgroundColor = videoSources[index].backgroundColor;

    const spinner = document.createElement('div');
    spinner.className = 'loading-spinner';
    const ripple1 = document.createElement('div');
    const ripple2 = document.createElement('div');
    spinner.appendChild(ripple1);
    spinner.appendChild(ripple2);
    videoContainer.appendChild(spinner);

    const video = document.createElement('video');
    video.className = 'video splitted loading';
    video.loop = true;
    video.playsInline = true;
    video.muted = isMuted.current; // Start muted to allow autoplay

    const videoHeader = document.createElement('video');
    videoHeader.className = 'video-header';
    videoHeader.loop = false;
    videoHeader.playsInline = true;
    videoHeader.muted = true;

    const videoSrc = videoSources[index];

    // Function to detect if the device is iOS
    const isIOS = () => {
      return /iPhone|iPad|iPod/.test(navigator.platform);
    };

    // Function to detect if the browser is Safari (not Chrome or Firefox)
    const isSafari = () => {
      const ua = navigator.userAgent;
      return ua.includes('Safari') && !ua.includes('Chrome') && !ua.includes('CriOS') && !ua.includes('FxiOS');
    };
    
    if (isIOS() && isSafari() && !!videoSrc.hlsPath) {
      // Directly load HLS source for iOS Safari
      videoHeader.src = videoSrc.hlsHeaderPath;
      videoHeader.load();

      video.src = videoSrc.hlsPath;
      video.load();

      if (index === currentIndex) {
        videoHeader.play();
        video.play().catch(err => { 
          console.error('Error playing HLS video:', err);
          video.src = videoSrc.fallbackPath;
          video.load();
          video.play();
        });
      }
    } else if (Hls.isSupported() && !!videoSrc.hlsPath) {
      // Use HLS.js for other browsers
      const hlsheader = new Hls();
      hlsheader.loadSource(videoSrc.hlsHeaderPath);
      hlsheader.attachMedia(videoHeader);

      const hls = new Hls();
      hls.loadSource(videoSrc.hlsPath);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        // console.log('HLS manifest parsed for video index', index);
        if (index === currentIndex) {
          videoHeader.play();
          video.play().catch(err => { 
            console.error('Error playing HLS video:', err);
            hls.destroy();
            video.src = videoSrc.fallbackPath;
            video.load();
            video.play();
          });
        }
      });
      hls.on(Hls.Events.ERROR, (_event, data) => {
        console.error('HLS.js error', data);
        if (data.fatal) {
          hls.destroy();
          video.src = videoSrc.fallbackPath; // Switch to fallback if HLS fails
          video.load();
        }
      });
    } else {
      console.error('HLS is not supported.');
      // Use fallback source if HLS is not supported
      video.src = videoSrc.fallbackPath;
      video.load();
      if (index === currentIndex) video.play();
    }

    // Video event listeners for handling loading spinner
    video.addEventListener('loadeddata', () => {
      video.classList.remove('loading');
      spinner.style.display = 'none';
    });

    video.addEventListener('waiting', () => {
      spinner.style.display = 'block';
    });

    video.addEventListener('playing', () => {
      spinner.style.display = 'none';
    });

    video.addEventListener('error', () => {
      console.error(`Error loading video at index ${index}`);
      video.src = videoSrc.fallbackPath; // Switch to fallback source on error
      spinner.style.display = 'block';
    });

    const reelOverlayRefElement = document.getElementsByClassName('reel-overlay-element')[0];

    // clone reelDataRefElement and add index to class name
    const reelOverlayRefElementClone = reelOverlayRefElement.cloneNode(true) as HTMLElement;

    reelOverlayRefElementClone.className = `${reelOverlayRefElementClone.className} reel-overlay-element-${index}`;

    // add videoSrc title to cloned reelOverlayRefElementClone
    const reelOverlayRefElementCloneTitle = reelOverlayRefElementClone.querySelector('.reel-data-ref-info-title');
    if (reelOverlayRefElementCloneTitle) {
      reelOverlayRefElementCloneTitle.textContent = videoSrc.name;
    }

    const toggleSoundVideoRefEl = reelOverlayRefElementClone.querySelectorAll('.toggle-sound-video');
    forEach(toggleSoundVideoRefEl, (el) => {
      el.addEventListener('click', () => {
        toggleSoundVideo(video, !isMuted.current);
      });
    });

    const playButtonRefEl = reelOverlayRefElementClone.querySelectorAll('.eko-button');
    forEach(playButtonRefEl, (el) => {
      el.addEventListener('click', () => {
        openGame(index);
      });
    });

    videoContainer.appendChild(videoHeader);
    videoContainer.appendChild(video);
    videoContainer.appendChild(reelOverlayRefElementClone);

    section.appendChild(videoContainer);

    if (reelElement) {
      (reelElement as HTMLElement).appendChild(section);
    } else {
      console.error('reelElement is null, cannot append section.');
    }
  };

  const toggleSoundVideo = (video: HTMLVideoElement, isMutedNewValue: boolean, updateRef: boolean = true) => {
    const muteEls = document.getElementsByClassName('mute-video');
    const unmuteEls = document.getElementsByClassName('unmute-video');

    isMuted.current = updateRef ? isMutedNewValue : isMuted.current;
    video.muted = isMutedNewValue;

    if(updateRef) {
      forEach(muteEls, (el, index) => {
        (el as HTMLElement).style.display = isMutedNewValue ? 'none' : 'block';
        (unmuteEls[index] as HTMLElement).style.display = isMutedNewValue ? 'block' : 'none';
      });
    }
  };
  
  // Use a separate effect to trigger the scroll after the elements have loaded
  useEffect(() => {
    if (urlHashGameId && videoSources.length > 0) {
      const index = videoSources.findIndex((video: { gameId: string; }) => video.gameId === urlHashGameId);
      // console.log('Computed starting index based on URL hash:', index);
      if (index !== -1) {
        startingIndex.current = index;
        if (!isScrolledToPosition.current) {
          // Delay the scroll slightly to ensure sections are available
          requestAnimationFrame(setSpawnPoint);
        }
      } else {
        console.log('URL hash game ID not found in video sources');
      }
    } else {
      console.log('No URL hash or video sources not loaded');
    }
  }, [urlHashGameId, videoSources]);
  

  useEffect(() => {
    // Set initial height
    setReelHeight();
  
    // Track loaded sections to avoid redundant additions
    const loadedSections = new Set();
  
    // Add event listeners for window resize and orientation change
    window.addEventListener('resize', setReelHeight);
    window.addEventListener('orientationchange', setReelHeight);
  
    const reelElement = document.getElementsByClassName('reel')[0];
  
    const handleScroll = () => {
      const currentScrollY = reelElement.scrollTop;
      if (currentScrollY > lastScrollY.current) {
        setScrollDirection(SCROLL_DIRECTION.DOWN);
      } else if (currentScrollY < lastScrollY.current) {
        setScrollDirection(SCROLL_DIRECTION.UP);
      }
      lastScrollY.current = currentScrollY;
    };
  
    if (reelElement) {
      // console.log('Reel element found, setting up scroll listener');
      reelElement.addEventListener('scroll', handleScroll);
  
      // Find starting index if urlHashGameId is provided
      if (urlHashGameId) {
        const index = videoSources.findIndex((video: { gameId: string; }) => video.gameId === urlHashGameId);
        // console.log('Observer computed starting index based on URL hash:', index);
        if (index !== -1) {
          startingIndex.current = index;
        }
      }
  
      // Ensure all sections up to and including the starting index are added
      // console.log('********Adding sections up to starting index:', startingIndex.current);
      videoSources.slice(0, startingIndex.current + 3).forEach((_: any, index: number) => {
        addVideoSection(index);
        loadedSections.add(index); // Mark as loaded
      });
  
      // Function to load the next section in sequence based on scroll direction
      const loadNextSection = (currentIndex: number) => {
        if (scrollDirection === SCROLL_DIRECTION.DOWN && currentIndex + 1 < videoSources.length) {
          if (!loadedSections.has(currentIndex + 1)) {
            addVideoSection(currentIndex + 1);
            loadedSections.add(currentIndex + 1);
          }
        } else if (scrollDirection === SCROLL_DIRECTION.UP && currentIndex > 0) {
          if (!loadedSections.has(currentIndex - 1)) {
            addVideoSection(currentIndex - 1);
            loadedSections.add(currentIndex - 1);
          }
        }
      };
  
      // Call setSpawnPoint only after ensuring sections are loaded
      setTimeout(() => {
        if (!isScrolledToPosition.current && sectionsRef.current[startingIndex.current]) {
          setSpawnPoint();
        }
      }, 100); // Small delay to ensure sections are added
  
      observer.current = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const index = sectionsRef.current.indexOf(entry.target as HTMLElement);
              // console.log('Intersection observed for index:', index);
  
              if (!isInitialLoad.current) { // Only update hash after initial load
                setCurrentIndex(index);
  
                // Update URL hash with the current game ID for bookmarking
                // window.location.hash = videoSources[index].gameId;
                // evBusMain.emit('route-change', { url: '/video-reels#' + videoSources[index].gameId });
                updateHashDirectly(videoSources[index].gameId);
              }
  
              // Load the next section in sequence
              loadNextSection(index);
  
              // Always ensure the last section is added if we are near the end
              if (index >= videoSources.length - 2 && !loadedSections.has(videoSources.length - 1)) {
                // console.log("Adding the last section as we are near the end of the list");
                addVideoSection(videoSources.length - 1);
                loadedSections.add(videoSources.length - 1); // Mark last section as loaded
              }
  
              // Disconnect and observe only the next section in sequence
              observer.current?.disconnect();
              const nextIndex =
                scrollDirection === SCROLL_DIRECTION.DOWN ? index + 1 : Math.max(0, index - 1);
              if (sectionsRef.current[nextIndex]) {
                const section = sectionsRef.current[nextIndex];
                if (section) {
                  observer.current?.observe(section);
                }
              }
            }
          });
        },
        {
          root: reelElement,
          threshold: 0.5,
        },
      );
  
      // Start observing the initial section in sequence
      if (sectionsRef.current[startingIndex.current]) {
        const section = sectionsRef.current[startingIndex.current];
        if (section) {
          observer.current.observe(section);
        }
      }
  
      return () => {
        reelElement.removeEventListener('scroll', handleScroll);
        observer.current?.disconnect();
        window.removeEventListener('resize', setReelHeight);
        window.removeEventListener('orientationchange', setReelHeight);
      };
    } else {
      console.error(`Element with class ${props.className} not found.`);
    }
  }, [videoSources, scrollDirection, props]);  

  useEffect(() => {
    if (currentIndex !== null) {
      handleVideoPlayback();
    }
  }, [currentIndex, videos]);

  const contextValue = React.useMemo(() => {
    return {
      name: 'VideoReel',
    };
  }, [videoSources, currentIndex]);

  return (
    <ModuleElementDiv className={props.className} $styleText={props.styleText}>
      <div className="reel" ref={reelRef}></div>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default VideoReel;
