import store from '@/store';
import { FeatureCategory, FeatureSubcategory } from '../types/eventData';
import { logEvent } from './logEvent';

export const logDeleteWholeTicketEvent = (event: any, featureUsed: string) => {
  try {
    const betslip = store.getState().bets.betsSlip;
    logEvent(
      event,
      {
        feature_used: featureUsed,
        feature_category: FeatureCategory.Bets,
        feature_subcategory: FeatureSubcategory.Betslip,
      },
      {
        deleted_ticket: getTicketDetails(betslip),
      },
    );
  } catch (error) {
    console.error('Error logging event:', error);
  }
};

const getTicketDetails = (betsSlip: any) => {
  const currentTicket = betsSlip.tickets[betsSlip.currentTicket];
  const mapMatch = (match: any) =>
    ({
      mType: match?.match.mType,
      idSport: match?.idSport,
      idMatch: match?.idMatch,
      idBet: match?.idBet,
      idMb: match?.idMb,
      ...(match?.brmId && {
        brmId: match.brmId,
        brmMotivations: match.brmMotivations,
      }),
    }) as const;

  const { prematch, live } = currentTicket;

  return {
    prematch: prematch.selected.map(mapMatch),
    live: live.selected.map(mapMatch),
  };
};
