import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useMatches } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { uniqueId } from 'lodash-es';

import { useAppSelector } from '@/store';
import { DataElementContext } from '@/page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { useProcessList } from '@/page-components/utils/useProcessList';
import urlonStringify from '@/utils/urlon';
import { logBetRecommendationEvent } from '@/analytics';

import {
  crossListsFilter,
  formatMotivations,
  getBetSlipList,
  getFullList,
  getCommonStateSlice,
  RecommendedMatchType,
} from './utils';

type DataSource = {
  data: RecommendedMatchType[];
};

type DataSourceMap = Record<string, DataSource | undefined>;

type Market = {
  idBet: string;
  idMb: string;
};

export type RecommendedBetsProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

export type RecommendedBetsState = {
  currentPage: number;
  lastPage: number;
  betSlipList: RecommendedMatchType[];
  currentList: RecommendedMatchType[];
  fullList: RecommendedMatchType[];
  originalList: RecommendedMatchType[];
  displayCount: string;
  showLoader: boolean;
};

const IS_RECOMMENDED_BETS_ENABLED = window.config && window.config.recommendedBetsEnabled === '1';

const useDataSource = (sourceId: string) => {
  const authenticationToken = useAppSelector((state) => state.authentication.access_token);
  const [state, setState] = React.useState<DataSource>();

  React.useEffect(() => {
    if (authenticationToken) {
      if (!IS_RECOMMENDED_BETS_ENABLED) {
        return;
      }

      axios
        .get<DataSourceMap>(`${window.config.dataSourceApiUrl}/resolve/sources`, {
          headers: {
            Authorization: 'Bearer ' + authenticationToken,
          },
          params: {
            q: urlonStringify({
              ids: [sourceId],
            }),
          },
        })
        .then((response) => {
          const dataSource = response.data[sourceId];

          if (dataSource) {
            setState({
              ...dataSource,
              data: dataSource.data.filter((match) => match.mType !== 'live').map((match) => formatMotivations(match)),
            });
          }
        })
        .catch((error: Error) => {
          console.log(error);
        });
    }
  }, [authenticationToken]);

  return state;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const RecommendedBets = (componentProps: RecommendedBetsProps) => {
  let props = componentProps;
  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const { sourceId, filterByIdMatch, filterByMatchBets } = props.properties ?? {};
  const pageSize = parseInt(props.properties.pageSize);

  const { i18n } = useTranslation();
  const uriMatches = useMatches();
  const betsSlip = useAppSelector((state) => state.bets.betsSlip);
  const key = React.useRef(uniqueId('unique-recommended-bets-'));

  const isCurrentMatch = React.useMemo(() => {
    return filterByIdMatch && filterByIdMatch.length && filterByMatchBets && filterByMatchBets.length;
  }, [filterByIdMatch, filterByMatchBets]);

  const isFootballPage = React.useMemo(() => {
    if (uriMatches && uriMatches.length) {
      const params = uriMatches[0].params ?? {};

      return params.idSport === undefined || params.idSport === '1';
    }

    return false;
  }, [uriMatches]);

  const matchBetsMap = React.useMemo(() => {
    const map = new Map<string, Market>();

    if (isCurrentMatch) {
      filterByMatchBets.forEach((filterByMatchBet: Market) => {
        map.set(filterByMatchBet.idMb, filterByMatchBet);
      });
    }

    return map;
  }, [isCurrentMatch]);

  const [state, setState] = React.useState<RecommendedBetsState>({
    currentPage: 0,
    lastPage: 0,
    betSlipList: [],
    currentList: [],
    fullList: [],
    originalList: [],
    displayCount: '(0)',
    showLoader: false,
  });

  const dataSource = useDataSource(sourceId);

  const { data, changed, processed } = useProcessList(
    dataSource,
    i18n.language,
    key.current,
    dataSource?.data?.length ?? 0,
  );

  // This runs only when the data is fetched from server
  React.useEffect(() => {
    if (data.length && processed) {
      const originalList = isCurrentMatch
        ? data.filter(
            (match: RecommendedMatchType) => match.idMatch === filterByIdMatch && !!matchBetsMap.get(match.brmIdMb),
          )
        : data;

      // First find if any of bets are in the bet slip
      const betSlipList = getBetSlipList(betsSlip, originalList);

      // Filter available list based on what is found in the bet slip
      const fullList = getFullList(originalList, betSlipList);

      setState((prevState) => ({
        ...getCommonStateSlice(prevState, fullList, pageSize),
        betSlipList,
        fullList,
        originalList,
      }));
    }
  }, [data, changed, processed, betsSlip, pageSize, filterByIdMatch, filterByMatchBets]);

  // This runs whenever bet slip is updated
  React.useEffect(() => {
    // First find if any of bets are in the bet slip
    const betSlipList = getBetSlipList(betsSlip, state.originalList);

    const added: RecommendedMatchType[] = [];
    const removed: RecommendedMatchType[] = [];

    // Check for added elements since last state update
    betSlipList.forEach((match) => {
      const exists = state.betSlipList.find(crossListsFilter(match));

      if (!exists) {
        added.push(match);
      }
    });

    // Check for removed elements since last state update
    state.betSlipList.forEach((match) => {
      const exists = betSlipList.find(crossListsFilter(match));

      if (!exists) {
        removed.push(match);
      }
    });

    // We should process this only when at least a recommended bet was added or removed from the bet slip
    if (added.length || removed.length) {
      // Filter available list based on what is added in and removed from the bet slip
      const fullList = getFullList(state.fullList, added, removed);

      setState((prevState) => ({
        ...getCommonStateSlice(prevState, fullList, pageSize),
        betSlipList,
        fullList,
      }));
    }
  }, [betsSlip, state.originalList, state.fullList, state.betSlipList, pageSize]);

  const onNextPage = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      setState((prevState) => {
        logBetRecommendationEvent(e, prevState, props);

        const isLastPage = prevState.currentPage === prevState.lastPage;
        const nextPage = isLastPage ? 0 : prevState.currentPage + 1;
        const pageStartIndex = isLastPage ? 0 : nextPage * pageSize;

        return {
          ...prevState,
          currentPage: nextPage,
          currentList: prevState.fullList.slice(pageStartIndex, pageStartIndex + pageSize),
        };
      });
    },
    [pageSize],
  );

  const contextValue = React.useMemo(() => {
    return {
      list: state.currentList,
      displayCount: state.displayCount,
      showLoader: state.showLoader,
      hideHeader: props.properties.hideHeader,
      hideTeams: props.properties.hideTeams,
      onNextPage,
    };
  }, [dataElementContext, componentProps, isCurrentMatch, state.currentList, state.displayCount, state.showLoader]);

  if (!IS_RECOMMENDED_BETS_ENABLED || !isFootballPage || !state.currentList.length) {
    return null;
  }

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default RecommendedBets;
