import axios from 'axios';
import * as storeActions from './../store/actions';

// account problem response message 21, 22 and 23
const ACCOUNT_SUSPENDED = 21;
const ACCOUNT_CLOSED = 22;
const ACCOUNT_SELF_EXCLUDED = 23;

class ClientAPI {
  constructor() {
    this.initialized = false;

    this.instance = null;
    this.store = null;
    this.appBaseUrl = window.config.front_url;
  }

  setStore(store) {
    this.store = store;

    this.init();
  }

  init() {
    if (!this.store) {
      return;
    }

    if (this.initialized) {
      return;
    }

    this.initialized = true;

    this.instance = axios.create({
      baseURL: this.appBaseUrl,
    });

    this.instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    this.instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    this.instance.defaults.headers.common['Platform-Type'] = window.config.platformType;

    this.activateRequestInterceptors();

    this.activateResponseInterceptors();
  }

  activateRequestInterceptors() {
    if (!this.instance) {
      return;
    }

    // let self = this;
    this.instance.interceptors.request.use(
      (request) => {
        if (typeof request['data'] === 'undefined') {
          request.data = {};
        }

        if (typeof request.data['platformType'] === 'undefined') {
          request.data.platformType = window.config.platformType;
        }

        return request;
      },
      (error) => {
        console.error(`[ERROR] Server request error => ${error}`);
        console.error('[NETWORK] Error, network disconnected!');

        return Promise.reject(error);
      },
    );
  }

  activateResponseInterceptors() {
    if (!this.instance) {
      return;
    }

    this.instance.interceptors.response.use(
      (response) => {
        // check if responseType is blob
        if (
          typeof response['config'] !== 'undefined' &&
          typeof response.config['responseType'] !== 'undefined' &&
          'blob' === response.config.responseType
        ) {
          if ('text/csv' === response.data.type) {
            // if (!(response.data.size > 3)) {
            //   alert('empty');
            //   return;
            // }
            return this.store.dispatch(storeActions.downloadHistoryCSV(response));
          }
        }

        if (typeof response['data'] === 'undefined') {
          return;
        }
        let responseData = response.data;
        if (typeof responseData['coins'] !== 'undefined') {
          return responseData;
        }
        if (typeof responseData['rows'] !== 'undefined') {
          if (typeof responseData['success'] !== 'undefined') {
            if (responseData.success) {
              return responseData;
            }
          }
        }

        if (typeof responseData['type'] === 'undefined') {
          console.info('Response object has no TYPE!!!');
          // return self.executeGeneralError();
          return;
        }

        if (typeof responseData['result'] !== 'undefined') {
          if (
            typeof responseData.result['ResponseCode'] !== 'undefined' &&
            [ACCOUNT_SUSPENDED, ACCOUNT_CLOSED, ACCOUNT_SELF_EXCLUDED].includes(responseData.result.ResponseCode)
          ) {
            let responseMessage = null;
            if (typeof responseData.result['ResponseMessage'] !== 'undefined') {
              responseMessage = responseData.result.ResponseMessage;
            }
            // this.store.dispatch(storeActions.logout());
            this.store.dispatch(
              storeActions.openAccountProblemModal(responseData.result.ResponseCode, responseMessage),
            );
            // console.log(responseMessage);
          }
        }

        switch (responseData['type']) {
          case 'menu':
          case 'authorization':
          case 'sms-code':
          case 'refresh_token':
          case 'wallet-preview':
          case 'phone-validation':
          case 'mail-validation':
          case 'cnp-validation':
          case 'profile':
          case 'responsible-gaming':
          case 'responsible-gaming-set-limit':
          case 'set-limit':
          case 'transactions-history':
          case 'password':
          case 'phone-number-validation':
          case 'sms-code-validation':
          case 'pending-withdrawals':
          case 'pending-viva-withdrawals':
          case 'locations':
          case 'tax':
          case 'cancel-withdraw':
          case 'cancel-viva-withdraw':
          case 'documents-preview':
          case 'documents-uploaded':
          case 'max-upload-size':
          case 'games':
          case 'game':
          case 'post-complaint':
          case 'game-rules':
          case 'jackpot':
          case 'jackpots':
          case 'latest-winners-slots':
          case 'latest-winners-jackpot':
          case 'signup':
          case 'get-chat-info':
          case 'withdraw-request':
          case 'reset_password_email_sent':
          case 'reset-password-with-token':
          case 'verify-phone':
          case 'forfeit':
          case 'token-email-validation':
          case 'pay-checkout':
          case 'withdraw-safecharge':
          case 'resent_email_verification':
          case 'promotions':
          case 'eligible-bonuses':
          case 'bonuses':
          case 'game-collections':
          case 'withdraw-code':
          case 'free-bet-get-list':
          case 'free-bet-get-history':
          case 'free-bet-dump-history':
          case 'viva-auth':
          case 'viva-charge-token':
          case 'viva-card-token':
          case 'viva-withdraw':
          case 'preeuro':
          case 'redeem-code':
          case 'bonus-available':
          case 'claim-bonus':
          case 'free-spin-get-list':
          case 'free-spin-get-history':
          case 'free-spins-dump-history':
          case 'scan-document':
          case 'marketing-offer':
          case 'get-partial-account':
          case 'set-partial-account':
          case 'self-exclude':
          case 'bridger-deposit-initiate':
          case 'bridger-fingerprint':
          case 'bridger-deposit':
          case 'bridger-withdraw':
          case 'deposit':
          case 'bonus-calculator':
          case 'countries':
          case 'okto-create-payment-code':
          case 'okto-get-customer':
          case 'withdraw-payment-methods':
          case 'withdraw-init':
          case 'withdraw-history':
          case 'withdraw-cancel':
          case 'link-iban':
          case 'cancel-withdraw-new':
          case 'amity-auth':
          case 'player-deposit-payment-methods':
            return responseData;
          default:
            // call something when type is not recognized
            break;
        }
      },
      (error) => {
        const { response } = error;

        if (response.status === 401) {
          // PHP Proxy returns 401 when the core returns any errors
          // we need to check if the error is Unauthorized and logout the user
          if (response?.data?.result?.http?.[401] === 'Unauthorized') {
            // somehow the auth token is not valid anymore. we need to clear it
            const store = this.store;
            setTimeout(() => {
              store.dispatch(storeActions.logout());
            }, 0);
          }
        }

        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
          switch (response.data.type) {
            case 'get-chat-info':
              if (typeof response.data.result !== 'undefined') {
                return response.data;
              }
              break;
            case 'authorization':
            case 'password':
            case 'scan-document':
            case 'marketing-offer':
            case 'documents-uploaded':
            case 'signup':
            case 'viva-auth':
            case 'viva-charge-token':
            case 'viva-card-token':
            case 'viva-withdraw':
            case 'okto-create-payment-code':
            case 'withdraw-payment-methods':
            case 'cancel-withdraw-new':
            case 'withdraw-init':
            case 'withdraw-history':
            case 'withdraw-cancel':
            case 'link-iban':
            case 'amity-auth':
              return response.data;
            case 'game':
              break;
            default:
              break;
          }
        } else {
          console.log(`[ERROR] Response status is ${response.status}`);

          console.log(response);
          return Promise.reject(error);
        }
      },
    );
  }

  getInstance() {
    return this.instance;
  }

  getStore() {
    return this.store;
  }
}

export default new ClientAPI();
