import { getUserData } from '../utils/getUserData';
import { getPageData } from '../utils/getPageData';
import { trackEvent } from '../trackingHooks/trackEvent';
import { EventData, FeatureCategory, FeatureSubcategory, ProductData } from '../types/eventData';
import { getOrCreateSessionId } from '../utils/generateSessionId';
import { getInteractionData } from '../utils/getInteractionData';
import store from '@/store';
import { ifAnalyticsEnabled } from '../utils/analyticsUtils';

/**
 * The main function to log an event.
 *
 * @param event The click event object.
 */
export const logEvent = ifAnalyticsEnabled((
  event: Event | MouseEvent | TouchEvent | KeyboardEvent | null,
  product: ProductData = {
    feature_used: 'unspecified',
    feature_category: FeatureCategory.Unspecified,
    feature_subcategory: FeatureSubcategory.Unspecified,
  },
  event_payload: any = {},
  page_visit_id: string = localStorage.getItem('visit_id') || '',
  referrer: string = document.referrer,
) => {
  try {
    const profileState = store.getState().profile;

    const eventData: EventData = {
      session_id: getOrCreateSessionId(),
      page_visit_id: page_visit_id,
      timestamp: new Date().toISOString(),
      user: {
        ...getUserData(profileState),
        location: { ip_address: '', city: '', country: '', region: '' }, // Placeholder
      },
      page: { ...getPageData(referrer) },
      interaction: getInteractionData(event),
      product: product,
      event_payload: event_payload,
    };

    // Send the event data to the tracking system
    trackEvent(eventData);
  } catch (error) {
    console.error('Error logging event:', error);
  }
});
