import React from 'react';
import styled from 'styled-components';

import { useAppSelector, useAppDispatch } from '../../../store';
import { useTranslation } from 'react-i18next';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { ConfigReducer as AllCountriesReducer, setNewCountry } from '@/store/slices/allCountries';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import Flags from '@/modules/lotto/assets/countries';
import { cloneDeep } from 'lodash';

import './index.scss';
import Axios from 'axios';

type CountrySelectorProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    countryCode: string;
    dsType: string;
    onValidation: any;
  };
};

type ModuleStateProps = {
  currentCountry: any;
  oldCountry: string;
  isValid: boolean;
  isOpen: boolean;
  countriesList: any;
  errorMessage: string;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const CountrySelector = (componentProps: CountrySelectorProps) => {
  let props = componentProps;
  const { t } = useTranslation();
  const allCountries = useAppSelector((state) => state.allCountries as AllCountriesReducer);
  const dataElementContext = React.useContext(DataElementContext);

  [props] = processComponentProps(props, dataElementContext);

  const dispatch = useAppDispatch();

  const [state, setState] = React.useState<ModuleStateProps>({
    currentCountry: '',
    oldCountry: '',
    isValid: false,
    isOpen: false,
    countriesList: [],
    errorMessage: '',
  });

  React.useEffect(() => {
    if (allCountries?.countries && allCountries?.currentCountry) {
      const countries = cloneDeep(allCountries.countries) ?? [];

      countries.forEach((country) => {
        country.countryUrl = (Flags as { [id: string]: string })[country.countryCode.toLowerCase()] ?? '';
      });

      const selectedCountry = countries.find((country) => country.countryCode === allCountries.currentCountry);

      setState((prevState) => ({
        ...prevState,
        currentCountry: selectedCountry,
        countriesList: { countries },
      }));
    }
  }, [allCountries]);

  React.useEffect(() => {
    if (props?.properties?.countryCode) {
      const oldCountry = state.currentCountry;

      if (!(state?.countriesList?.countries && Array.isArray(state?.countriesList?.countries))) return;

      const selectedCountry = state?.countriesList?.countries.find(
        (country: any) => country.countryCode === props?.properties?.countryCode?.toUpperCase?.(),
      );
      setState((prevState) => ({
        ...prevState,
        currentCountry: selectedCountry,
        oldCountry: oldCountry?.countryCode,
      }));
    }
  }, [props?.properties?.countryCode]);

  const onValidatePhone = async (value: string) => {
    if (value) {
      try {
        const response = await Axios.get(window.config.front_url + '/app/validatePhone', {
          params: {
            phoneNumber: '+' + state?.currentCountry?.phoneCode + value?.replace(/\s/g, ''),
            countryCode: state.currentCountry.countryCode,
          },
        });

        if (response.data.result) {
          setState((prevState) => ({
            ...prevState,
            isValid: response.data.result.isValid,
            errorMessage: response.data.result.isValid === true ? '' : t('Invalid phone number.'),
          }));
          if (typeof props?.properties?.onValidation === 'function')
            props.properties.onValidation(response.data.result.isValid);
          // const element: HTMLElement | null = document.querySelector('.wl-input-phone-number');
          // const errorMessage: HTMLElement | null = document.querySelector('.err-txt');
          // if (!response.data.result.isValid) {
          //   if (element) element.classList.add('st-error');
          //   if (errorMessage) errorMessage.innerHTML = t('Invalid phone number.');
          // } else {
          //   if (element) element.classList.remove('st-error');
          // }
        }
      } catch (err: any) {
        const errResp = { error: err.toString() };
      }
    }
  };

  const onChangeCountry = (e: React.MouseEvent<HTMLElement>) => {
    const oldCountry = state.currentCountry ?? '';
    const value = e.currentTarget?.dataset?.country ?? null;
    let selectedCountry: any = '';

    if (value) selectedCountry = state?.countriesList?.countries.find((country: any) => country.countryCode === value);

    // console.log('Country Selector', { e, value, selectedCountry });

    setState((prevState) => ({
      ...prevState,
      currentCountry: selectedCountry,
      oldCountry: oldCountry?.countryCode,
    }));

    if (selectedCountry) {
      dispatch({ type: 'allCountries/setNewCountry', payload: selectedCountry.countryCode });
    }
  };

  const onToggleCountrySelector = () => {
    setState((prevState) => ({
      ...prevState,
      isOpen: !prevState.isOpen,
    }));
  };

  const contextValue = {
    countries: state.countriesList.countries,
    currentCountry: state.currentCountry,
    oldCountry: state.oldCountry,
    onValidatePhone: onValidatePhone,
    isValid: state.isValid,
    errorMessage: state.errorMessage,
    parentContext: dataElementContext,

    onToggleCountrySelector,
    onChangeCountry,
  };

  React.useEffect(() => {
    console.log('Country Selector', { props, contextValue, selectedCountry: state.currentCountry, state });
  }, [state.currentCountry, state.errorMessage, state.isValid]);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default CountrySelector;
