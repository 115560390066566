import React from 'react';
import styled from 'styled-components';
import { useMatches } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { useAppDispatch, useAppSelector } from '../../../../../store';
import { liveFetchMatches } from '../../../../../modules/bets/store/actions/live';
import { DataElementContext } from '../../../../../page-components/common/DataElementContext';
import { getData } from '../../utils/hooks';
import {
  testValues,
  liveScore,
  formatDate,
  formatOddName,
  formatBetTitle,
  canCashout,
  getTr,
  cloneOmit,
  floorFigure,
} from '../../utils/functions';
import crests from '../../../../../modules/bets/utils/crests';

import './index.scss';
import { CategoryType, MatchType, SportType, TournamentType } from '../../utils/types';

type SportProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const groupByTournaments = (res: any, data: any, filterByDate: number | null | undefined, matchesList: any) => {
  if (data?.idMatch && matchesList[data.idMatch]) {
    const key = `${data.idSport}-${data.idCategory}-${data.idTournament}`;

    if (filterByDate && filterByDate !== 0) {
      const d = moment(data.matchDateTime).startOf('day').valueOf();

      if (d != filterByDate) {
        return;
      }
    }

    if (res[key]) {
      res[key].matches.push(matchesList[data.idMatch]);
    } else {
      res[key] = {
        sport: {
          idSport: data.idSport,
        },
        category: {
          idCategory: data.idCategory,
        },
        tournament: {
          idTournament: data.idTournament,
          tournamentName: data.tournamentName,
        },
        matches: [matchesList[data.idMatch]],
      };
    }

    return;
  }

  data &&
    Object.keys(data).forEach((key) => {
      groupByTournaments(res, data[key], filterByDate, matchesList);
    });
};

const buildList = (matches: any, matchesList: any, path: string[], filterByDate?: string | number | null): any[] => {
  const reducedList = path.reduce((obj, key) => obj?.[key], matches);

  const res = {};
  const fd = typeof filterByDate === 'string' ? parseInt(filterByDate) : filterByDate;
  groupByTournaments(res, reducedList, fd, matchesList);

  return Object.values(res);
};

const extractUriParams = (match: any, props: any) => {
  let idSport = props.properties.idSport;
  let idCategory = props.properties.idCategory;
  let idTournament = props.properties.idTournament;
  let idMatch = props.properties.idMatch;

  let paramIdSport = props.properties.paramIdSport ?? 'idSport';
  if (paramIdSport[0] === ':') paramIdSport = paramIdSport.substr(1);
  if (match.params && paramIdSport && match.params[paramIdSport] != null) {
    idSport = match.params[paramIdSport];
  }

  let paramIdCategory = props.properties.paramIdCategory ?? 'idCategory';
  if (paramIdCategory[0] === ':') paramIdCategory = paramIdCategory.substr(1);
  if (match.params && paramIdCategory && match.params[paramIdCategory] != null) {
    idCategory = match.params[paramIdCategory];
  }

  let paramIdTournament = props.properties.paramIdTournament ?? 'idTournament';
  if (paramIdTournament[0] === ':') paramIdTournament = paramIdTournament.substr(1);
  if (match.params && paramIdTournament && match.params[paramIdTournament] != null) {
    idTournament = match.params[paramIdTournament];
  }

  let paramIdMatch = props.properties.paramIdMatch ?? 'idMatch';
  if (paramIdMatch[0] === ':') paramIdMatch = paramIdMatch.substr(1);
  if (match.params && paramIdMatch && match.params[paramIdMatch] != null) {
    idMatch = match.params[paramIdMatch];
  }
  return [idSport ?? '1', idCategory, idTournament, idMatch];
};

const buildOutcomes = (
  match: MatchType,
  bets: any,
  selectedBets: any,
  language: string,
  isWinnerFun: boolean,
  ukLayout: boolean = false,
  ukSelectedBet: string | null = null,
  ukSelectedArgument: number | null = null,
) => {
  if (!match?.bettingStatus) return [];

  const matchBets = match.matchBets.filter((market) => {
    if (!market.mbActive) return false;
    if (market.mbOutcomes.length === 0) return false;

    const hasActive = market.mbOutcomes.find((outcome) => outcome.mboActive === true);
    return !!hasActive;
  });

  matchBets.sort((a, b) => {
    const tv = testValues(a.mbPosition, b.mbPosition);
    if (tv !== 0) {
      return tv;
    }

    return testValues(a.mbSpecialValue, b.mbSpecialValue);
  });

  if (matchBets.length === 0) return [];

  const market = matchBets.find((bet) => {
    // exclude ultra odds from winner fun
    if (isWinnerFun && bet.idBet.indexOf('dup') === 0) return false;
    if (ukLayout && ukSelectedBet && bet.idBet !== ukSelectedBet) return false;

    return true;
  });
  const marketName = formatBetTitle(market, match, bets, language);

  const res: any[] = [];

  const selectedHash: any = {};
  selectedBets.forEach((bet: any) => {
    selectedHash[bet.idMbo] = true;
  });

  market?.mbOutcomes.forEach((outcome) => {
    if (outcome.mboActive) {
      let oddName = formatOddName(market.idBet, outcome, match, bets, language);
      if (match.idSport === '3' && (oddName === '1' || oddName === 'Win1')) {
        oddName = getTr(match.team1Name, language);
        oddName = oddName.replace('/', ' / ');
      } else if (match.idSport === '3' && (oddName === '2' || oddName === 'Win2')) {
        oddName = getTr(match.team2Name, language);
        oddName = oddName.replace('/', ' / ');
      }

      if (
        ukLayout &&
        ukSelectedArgument != null &&
        outcome.argument != null &&
        outcome.argument.toString() !== ukSelectedArgument.toString()
      )
        return;

      const data = {
        mType: match.mType,
        idSport: match.idSport,
        idMatch: match.idMatch,
        idBet: market.idBet,
        idMb: market.idMb,
        idBo: outcome.idBo,
        idMbo: outcome.idMbo,
        oddValue: floorFigure(outcome.mboOddValue),
        changeDir: outcome.changeDir,
        oddName: oddName,
        marketName: marketName,
        selected: !!selectedHash[outcome.idMbo],
      };

      res.push(data);
    }
  });

  return res;
};

const Sport = (componentProps: SportProps) => {
  const { i18n, t } = useTranslation();

  const [mounted, setMounted] = React.useState(false);
  const uriMatches = useMatches();
  const dispatch = useAppDispatch();

  const {
    sports,
    categories,
    tournaments,
    matches,
    matchesList,
    loadingState,
    bets,
    selectedBets,
    fullStateLoaded,
    inPageIdMatch,
    isWinnerFun,
  } = getData({
    type: 'live',
  });

  const ukLayout = componentProps.properties.ukLayout ?? false;

  const ukSelectedBet: string | null = useAppSelector((state) => state.bets.app.ukSelectedBet);
  const ukSelectedArgument: string | null = useAppSelector((state) => state.bets.app.ukSelectedArgument);
  const ukMarketsBySport: any = useAppSelector((state) => state.bets.app.ukMarketsBySport);

  const oddLimiterEnabled = useAppSelector<any>((state) => state.bets.app.oddLimiterEnabled);
  const oddLimiterValue = useAppSelector<any>((state) => state.bets.app.oddLimiterValue);

  const [expanded, setExpanded] = React.useState<{ [index: string]: boolean }>({});
  const requestedMatches = React.useRef<any>({});

  // try to initialize gameId from the properties object
  let idSport = componentProps.properties.idSport;
  let idCategory = componentProps.properties.idCategory;
  let idTournament = componentProps.properties.idTournament;
  let idMatch = componentProps.properties.idMatch;

  if (componentProps.properties.targetIdFromPath) {
    if (uriMatches && uriMatches.length) {
      // there is no gameId set as componentProps; maybe were in a route that includes a :gameId param
      const match = uriMatches[0];

      [idSport, idCategory, idTournament, idMatch] = extractUriParams(match, componentProps);
    }
  }

  React.useEffect(() => {
    setMounted(true);
  }, []);

  const onToggle = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (e.currentTarget.dataset.key) {
      const key = e.currentTarget.dataset.key;
      const expanded = e.currentTarget.dataset.expanded;
      setExpanded((v) => ({
        ...v,
        [key]: v[key] == null && expanded === 'true' ? false : !v[key],
      }));
    }
  };

  let displayList: {
    index?: number;
    key: string;
    id: string;
    expanded?: boolean;
    sport: SportType;
    tournament: TournamentType;
    category: CategoryType;
    matches: MatchType[];
    totalMatches?: number;
    onToggle?: (e: React.MouseEvent<HTMLElement>) => void;
    ukOutcomes?: any[];
  }[] = [];
  let error = '';

  const buildListParams = [];

  if (idSport) {
    if (sports[idSport] != null && sports[idSport].sportActive) {
      if (idCategory) {
        if (categories[idCategory] != null && categories[idCategory].categoryActive) {
          if (idTournament) {
            if (tournaments[idTournament] != null && tournaments[idTournament].tournamentActive) {
              if (idMatch) {
                if (matchesList?.[idMatch] != null) {
                  buildListParams.push(idSport, idCategory, idTournament, idMatch);
                } else {
                  // no match
                  error = 'Match not found';
                }
              } else {
                // display all matches from league
                buildListParams.push(idSport, idCategory, idTournament);
              }
            } else {
              error = 'League not found';
            }
          } else {
            // display all leagues from group
            buildListParams.push(idSport, idCategory);
          }
        } else {
          error = 'Group not found';
        }
      } else {
        // display all groups from sport
        buildListParams.push(idSport);
      }
    } else {
      error = 'Sport not found';
    }
  }

  if (!error && buildListParams.length > 0) {
    if (buildListParams.length === 4) {
      const match = matchesList[idMatch];
      const key = `${match.idSport}-${match.idCategory}-${match.idTournament}`;
      displayList.push({
        sport: sports[idSport],
        category: categories[idCategory],
        tournament: tournaments[idTournament],
        matches: [{ ...match }],
        key: key,
        id: key,
      });
    } else {
      displayList = buildList(matches, matchesList, buildListParams, null);
    }
  }

  displayList = displayList.filter((item) => item.matches.length > 0);

  displayList.sort((a, b) => {
    if (!a && !b) return 0;
    if (!a) return 1;
    if (!b) return -1;

    let aTournamentPosition;
    if (tournaments[a.tournament?.idTournament]) {
      aTournamentPosition = tournaments[a.tournament.idTournament].tournamentPosition;
    }
    let bTournamentPosition;
    if (tournaments[b.tournament?.idTournament]) {
      bTournamentPosition = tournaments[b.tournament.idTournament].tournamentPosition;
    }

    const tv = testValues(aTournamentPosition, bTournamentPosition, false);
    if (tv !== 0) {
      return tv;
    }

    const lang: any = {
      en: '2',
      ro: '42',
    };

    return testValues(
      a.tournament?.tournamentName?.[lang[i18n.language]]?.toUpperCase(),
      b.tournament?.tournamentName?.[lang[i18n.language]]?.toUpperCase(),
      false,
    );
  });

  displayList.forEach((item, index) => {
    const id: string[] = [];

    if (sports[item.sport?.idSport]) {
      item.sport = sports[item.sport.idSport];
      id.push(item.sport.idSport);
    }
    if (categories[item.category?.idCategory]) {
      item.category = categories[item.category.idCategory];
      id.push(item.category.idCategory);
    }
    if (tournaments[item.tournament?.idTournament]) {
      item.tournament = tournaments[item.tournament.idTournament];
      id.push(item.tournament.idTournament);
    }

    const key = `${index}-${id.join('-')}`;
    item.key = key;
    item.id = key;

    item.expanded = expanded[key] ?? false;
    if (index < 5 && expanded[key] == null) {
      item.expanded = true;
    }

    item.index = index;
    item.onToggle = onToggle;
    item.totalMatches = item.matches.length;

    if (sports[item.sport?.idSport]) {
      item.sport = sports[item.sport.idSport];
    }
    if (categories[item.category?.idCategory]) {
      item.category = categories[item.category.idCategory];
    }
    if (tournaments[item.tournament?.idTournament]) {
      item.tournament = tournaments[item.tournament.idTournament];
    }

    if (ukLayout && ukSelectedBet) {
      const selectedBetData = ukMarketsBySport[item.sport.idSport]?.find((bet: any) => bet.idBet === ukSelectedBet);

      if (selectedBetData) {
        item.ukOutcomes = selectedBetData.outcomes;
      }
    }

    if (item.expanded) {
      item.matches.sort((a, b) => {
        if (!a && !b) return 0;
        if (!a) return 1;
        if (!b) return -1;

        if (a.position === b.position) {
          return a.matchDateTime - b.matchDateTime;
        }
        return a.position - b.position;
      });

      item.matches.forEach((match, index) => {
        const newMatch = cloneOmit(match, ['matchBets', 'currentStatus', 'periods']);
        newMatch.inPageSelected = inPageIdMatch === match.idMatch;

        newMatch.canCashout = canCashout(match);

        newMatch.matchDateTimeString = formatDate(match.matchDateTime, i18n.language, false);

        newMatch.outcomes = buildOutcomes(
          match,
          bets,
          selectedBets,
          i18n.language,
          isWinnerFun,
          ukLayout,
          ukSelectedBet,
          ukSelectedArgument,
        );

        if (oddLimiterEnabled) {
          const exists = newMatch.outcomes.find((outcome: any) => {
            return outcome.oddValue <= oddLimiterValue;
          });

          if (!exists) {
            newMatch.outcomes = [];
          }
        }

        if (match.mType === 'live') {
          const ls = liveScore(match, match.currentStatus);
          if (Array.isArray(ls.intervals) && ls.intervals.length > 0) {
            ls.intervals = ls.intervals.map((interval) => {
              return { percent: interval };
            });
          }
          newMatch.scoreInfo = ls;
        }

        newMatch.team1LogoUrl = crests(match.team1Name, match, 1);
        newMatch.team2LogoUrl = crests(match.team2Name, match, 2);

        item.matches[index] = newMatch;
      });

      if (oddLimiterEnabled) {
        item.matches = item.matches.filter((match) => {
          if (!match.outcomes.length) return false;
          return true;
        });
      }
    }
  });

  if (oddLimiterEnabled) {
    displayList = displayList.filter((element) => {
      if (!element.matches.length && element.expanded) return false;
      return true;
    });
  }

  const contextValue = {
    list: displayList,
    listTotal: displayList.length,
    loading: loadingState != null,
    fullStateLoaded: fullStateLoaded,
  };

  React.useEffect(() => {
    if (fullStateLoaded) {
      const fetchMatches: any = [];

      for (const entry of contextValue.list) {
        if (!entry.expanded) continue;
        entry.matches.forEach((match) => {
          if (!match._loaded && !requestedMatches.current[match.idMatch]) {
            requestedMatches.current[match.idMatch] = true;
            fetchMatches.push(match.idMatch);
          }
        });
      }

      if (fetchMatches.length > 0) {
        dispatch(liveFetchMatches(fetchMatches));
      }
    }
  }, [contextValue, fullStateLoaded]);

  //console.log('SportLive[contextValue]', contextValue);

  if (!mounted) return null;

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default Sport;
