import { FeatureCategory, FeatureSubcategory } from '../types/eventData';
import { logEvent } from './logEvent';
// Helper function for logging the event
export const logSearchBarEvent = (
  event: Event | MouseEvent | TouchEvent | KeyboardEvent | null,
  searchBarAction: string,
  event_payload: any,
) => {
  try {
    logEvent(
      event,
      {
        feature_used: `search-bar_${searchBarAction}`,
        feature_category: FeatureCategory.SearchBar,
        feature_subcategory: FeatureSubcategory.NotApplicable,
      },
      event_payload,
    );
  } catch (error) {
    console.error('Error logging search event:', error);
  }
};
