import { FeatureCategory, FeatureSubcategory } from '../types/eventData';
import { logEvent } from './logEvent';

// Helper function for logging the event
export const logBetEvent = (event: any, featureUsed: string, betData: any) => {
  try {
    // Bundle all analytics-specific work into the logEvent call
    // so it only runs if analytics are enabled
    logEvent(
      event,
      {
        // feature_used: identifyFeatureUsed(betDetails),
        feature_used: featureUsed,
        feature_category: identifyFeatureCategory(betData),
        feature_subcategory: identifyFeatureSubcategory(betData),
      },
      { match_details: betData },
    );
  } catch (error) {
    console.error('Error logging event:', error);
  }
};

/**
 * Identify the feature used based on the bet details.
 *
 * @returns A string representing the feature used.
 */
const identifyFeatureUsed = (betDetails: any): string => {
  return 'Added to betslip';
};

/**
 * Identify the feature category based on the bet details.
 *
 * @returns A string representing the feature category.
 */
const identifyFeatureCategory = (betData: any): FeatureCategory => {
  if (betData.brmid) {
    return FeatureCategory.BetsRecommendation;
  }

  return FeatureCategory.Bets;
};

/**
 * Identify the feature subcategory based on the bet details.
 *
 * @returns A string representing the feature subcategory.
 */
const identifyFeatureSubcategory = (betData: any): FeatureSubcategory => {
  // what would a feature subcategory be?

  return FeatureSubcategory.NotApplicable;
};
