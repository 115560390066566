import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import {
  sendSelfExclusionCodes,
  setSelfexclusion,
  selfExclude,
  checkPassword,
  clearPasswordCache,
} from '../../../modules/casino/store/actions/profile';
import { logout } from '../../../modules/casino/store/actions/login';
import ResponseErrorMessages from '../../../modules/casino/store/errors/ResponseErrorMessages';

import './index.scss';

type TakeABreakProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

type ModuleStateProps = {
  currentStep: string;
  agree: boolean;
  longerPeriod: boolean;
  // --- legacy props
  period: string;
  periodText: string;
  period1Value: string;
  period1Text: string;
  period2Value: string;
  period2Text: string;
  period3Value: string;
  period3Text: string;
  // ---
  periods: PeriodHash;
  selectedPeriod: string;
  showConfirmWindow: boolean;
  counter: number;
  password: string;
  passwordHasErrors: boolean;
  passwordError: string;
  emailCode: string;
  emailCodeHasErrors: boolean;
  emailCodeError: string;
  smsCode: string;
  smsCodeHasErrors: boolean;
  smsCodeError: string;
  canLockAccount: boolean;
  showPassword: boolean;
  hasBeenChecked: boolean;
  hasGlobalError?: boolean;
  globalError?: string;
};

interface Hash {
  [key: string]: string;
}

interface PeriodHash {
  [key: string]: { value: string | number; text: string };
}

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const TakeABreak = (componentProps: TakeABreakProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const dispatch = useAppDispatch();
  const profile = useAppSelector((state) => state.profile);
  const navigate = useNavigate();
  const timerId = React.useRef(0);

  const mapPeriods = (periods: Hash): PeriodHash => {
    const result: PeriodHash = {};
    Object.keys(periods).forEach((key) => {
      result[key] = { value: key, text: periods[key] };
    });
    return result;
  };

  const periods: Hash = {
    '4': '1 day',
    '5': '3 days',
    '6': '7 days',
    '7': '6 months',
    '8': '1 year',
    '9': '3 years',
  };
  const shortPeriods = mapPeriods(window.config.selfExclusionSettings?.periods?.short || {});
  const longPeriods = mapPeriods(window.config.selfExclusionSettings?.periods?.long || {});
  const enableEmail = window.config.selfExclusionSettings?.enableEmail ?? '0';
  const enableOTP = window.config.selfExclusionSettings?.enableOTP ?? '0';

  const initialState = {
    currentStep: 'step1',
    agree: false,
    longerPeriod: false,
    // --- legacy props
    period: '4',
    periodText: periods['4'],
    period1Value: '4',
    period1Text: periods['4'],
    period2Value: '5',
    period2Text: periods['5'],
    period3Value: '6',
    period3Text: periods['6'],
    // ---
    periods: shortPeriods,
    selectedPeriod: '4',
    showConfirmWindow: false,
    counter: 10,
    password: '',
    passwordHasErrors: false,
    passwordError: '',
    emailCode: '',
    emailCodeHasErrors: false,
    emailCodeError: '',
    smsCode: '',
    smsCodeHasErrors: false,
    smsCodeError: '',
    canLockAccount: false,
    showPassword: false,
    hasBeenChecked: false,
    hasGlobalError: false,
    globalError: '',
  };

  const [state, setState] = React.useState<ModuleStateProps>(initialState);

  const contextValue = {
    ...state,
    loadingExclusion: profile.loading,

    onPeriodChoice: (e: React.FormEvent<HTMLInputElement>) => {
      if (e.currentTarget && e.currentTarget.dataset && e.currentTarget.dataset.value) {
        const value = e.currentTarget.dataset.value;

        setState((v) => ({
          ...v,
          selectedPeriod: value,
          // --- Legacy
          period: value,
          periodText: periods[value] != null ? periods[value] : '',
          // ---
        }));
      }
    },
    onChangeAgree: (e: React.FormEvent<HTMLInputElement>) => {
      setState((v) => ({
        ...v,
        agree: (e.target as HTMLInputElement).checked,
      }));
    },
    onToggleLongerPeriod: () => {
      setState((v) => {
        const newValues = { ...v };

        newValues.longerPeriod = !newValues.longerPeriod;

        if (newValues.longerPeriod) {
          // --- Legacy
          newValues.period = '7';
          newValues.periodText = periods['7'];
          newValues.period1Value = '7';
          newValues.period1Text = periods['7'];
          newValues.period2Value = '8';
          newValues.period2Text = periods['8'];
          newValues.period3Value = '9';
          newValues.period3Text = periods['9'];
          // ---
          newValues.periods = longPeriods;
          newValues.selectedPeriod = '7';
        } else {
          // --- Legacy
          newValues.period = '4';
          newValues.periodText = periods['4'];
          newValues.period1Value = '4';
          newValues.period1Text = periods['4'];
          newValues.period2Value = '5';
          newValues.period2Text = periods['5'];
          newValues.period3Value = '6';
          newValues.period3Text = periods['6'];
          // ---
          newValues.periods = shortPeriods;
          newValues.selectedPeriod = '4';
        }

        return newValues;
      });
    },

    onBack: () => {
      // 1st clear the timer
      clearInterval(timerId.current);
      setState({
        ...initialState,
      });
    },
    onConfirmChoice: () => {
      // if (['7', '8', '9'].indexOf(state.period) !== -1) {
      if (state.longerPeriod) {
        dispatch(sendSelfExclusionCodes());

        timerId.current = window.setInterval(() => {
          setState((v) => {
            if (v.counter <= 1) {
              clearInterval(timerId.current);
            }
            return {
              ...v,
              counter: v.counter - 1,
            };
          });
        }, 1000);
      }

      setState((v) => ({
        ...v,
        currentStep: v.longerPeriod ? 'step3' : 'step2',
      }));
    },
    onShowConfirmWindow: () => {
      setState((v) => ({
        ...v,
        showConfirmWindow: !v.showConfirmWindow,
      }));
    },

    onIdentityConfirmation: () => {
      setState((v) => ({
        ...v,
        currentStep: 'step4',
        agree: false,
      }));
    },
    onChangePassword: (e: React.FormEvent<HTMLInputElement>) => {
      const value = e.target ? (e.target as HTMLInputElement).value : '';
      dispatch(clearPasswordCache());
      setState((v) => ({
        ...v,
        password: value,
      }));
    },
    onChangeEmailCode: (e: React.FormEvent<HTMLInputElement>) => {
      const value = e.target ? (e.target as HTMLInputElement).value : '';
      dispatch(clearPasswordCache());
      setState((v) => ({
        ...v,
        emailCode: value,
      }));
    },
    onChangeSmsCode: (e: React.FormEvent<HTMLInputElement>) => {
      const value = e.target ? (e.target as HTMLInputElement).value : '';
      dispatch(clearPasswordCache());
      setState((v) => ({
        ...v,
        smsCode: value,
      }));
    },
    onLockAccount: () => {
      if (!profile.errorCode) {
        if (state.longerPeriod) {
          dispatch(
            selfExclude({
              limit_type: 2,
              limit_type_period: parseInt(state.selectedPeriod),
              password: state.password,
              sms_code: state.smsCode,
              email_code: state.emailCode,
            }),
          );
        } else {
          dispatch(clearPasswordCache());
          dispatch(checkPassword(state.password));
        }

        setState((v) => ({
          ...v,
          hasBeenChecked: true,
        }));
      }
    },
    onClickShowPassword: () => {
      setState((v) => ({
        ...v,
        showPassword: !v.showPassword,
      }));
    },
  };

  React.useEffect(() => {
    // handle locking results
    if (
      !profile.loadingCheckPassword &&
      profile.passwordIsValid != null &&
      profile.passwordIsValid &&
      !profile.errorCode
    ) {
      if (!state.longerPeriod) {
        // do the simple self exclusion
        dispatch(setSelfexclusion(2, parseInt(state.selectedPeriod)));
      }

      // we're done; logout the user and redirect to the self-excluded page
      dispatch(logout());
      navigate('/profile/take-a-break/self-excluded');
    }
  }, [
    profile.passwordIsValid,
    profile.loadingCheckPassword,
    state.selectedPeriod,
    state.longerPeriod,
    profile.errorCode,
  ]);

  // check if we have some errors to show
  if (state.currentStep === 'step4') {
    const NOT_AUTHENTICATED = 12;
    const INVALID_EMAIL_CODE = 42;
    const INVALID_SMS_CODE = 43;

    if (!profile.loadingCheckPassword && state.hasBeenChecked) {
      if (profile.errorCode) {
        if (profile.passwordIsValid) {
          switch (profile.errorCode) {
            case INVALID_EMAIL_CODE:
              contextValue.emailCodeHasErrors = true;
              contextValue.emailCodeError = 'Wrong email code, try again!';
              break;
            case INVALID_SMS_CODE:
              contextValue.smsCodeHasErrors = true;
              contextValue.smsCodeError = 'Wrong sms code, try again!';
              break;
            default:
              contextValue.hasGlobalError = true;
              contextValue.globalError = ResponseErrorMessages.get(profile.errorCode);
              break;
          }
        } else {
          if (profile.errorCode === NOT_AUTHENTICATED) {
            contextValue.passwordHasErrors = true;
            contextValue.passwordError = 'Wrong password, try again!';
          } else {
            contextValue.hasGlobalError = true;
            contextValue.globalError = ResponseErrorMessages.get(profile.errorCode);
          }
        }
      }
    }

    if (state.password && state.password.length > 5) {
      // enable the lock account button if the basic requirements are met
      contextValue.canLockAccount = true;
      if (enableEmail === '1' && !state.emailCode) contextValue.canLockAccount = false;
      if (enableOTP === '1' && !state.smsCode) contextValue.canLockAccount = false;
    }
  } else if (state.currentStep === 'step2') {
    if (!profile.loadingCheckPassword && profile.passwordIsValid === false && state.hasBeenChecked) {
      contextValue.passwordHasErrors = true;
      contextValue.passwordError = 'Wrong password, try again!';
    }
  }

  // console.log('DEBUG TAKE A BREAK: ', { contextValue, profile, state, timerId });

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default TakeABreak;
