import store from '@/store';
import { FeatureCategory, FeatureSubcategory } from '../types/eventData';
import { logEvent } from './logEvent';

interface ActionTypeConfig {
  category: (props?: string) => FeatureCategory;
  subcategory: (properties?: any) => FeatureSubcategory;
  getPayload: (props: any, context: any) => any;
}

const findMatchingBet = (properties: any, betsArray: any) => {
  const matchedBet = betsArray.find(
    (bet: any) =>
      bet.idBet === properties['data-idbet'] &&
      bet.idBo === properties['data-idbo'] &&
      bet.idMatch === properties['data-idmatch'] &&
      bet.idMb === properties['data-idmb'] &&
      bet.idMbo === properties['data-idmbo'] &&
      bet.idSport === properties['data-idsport'],
  );

  if (!matchedBet) {
    throw new Error('Could not find matching bet');
  }

  // return only the necessary properties
  return {
    brmId: matchedBet.brmId,
    idBet: matchedBet.idBet,
    idBo: matchedBet.idBo,
    idMatch: matchedBet.idMatch,
    idMb: matchedBet.idMb,
    idMbo: matchedBet.idMbo,
    idSport: matchedBet.idSport,
  };
};

const CAROUSEL_TYPE_HANDLERS: Record<string, ActionTypeConfig> = {
  'delete-match-bet': {
    category: (props: any) => {
      if (!props || !props.properties) {
        return FeatureCategory.Bets;
      }

      const betslip = store.getState().bets.betsSlip;
      const mType = props.properties['data-mtype'];

      if (!mType) {
        return FeatureCategory.Bets;
      }

      if (mType === 'live' && betslip.tickets[betslip.currentTicket]?.live.selected) {
        const liveBet = findMatchingBet(props.properties, betslip.tickets[betslip.currentTicket].live.selected);

        if (liveBet.brmId) {
          return FeatureCategory.BetsRecommendation;
        } else {
          return FeatureCategory.Bets;
        }
      }

      if (mType === 'prematch' && betslip.tickets[betslip.currentTicket]?.prematch.selected) {
        const prematchBet = findMatchingBet(props.properties, betslip.tickets[betslip.currentTicket].prematch.selected);

        if (prematchBet.brmId) {
          return FeatureCategory.BetsRecommendation;
        } else {
          return FeatureCategory.Bets;
        }
      }

      return FeatureCategory.Bets;
    },
    subcategory: () => FeatureSubcategory.Betslip,
    getPayload: (props, context) => {
      const betslip = store.getState().bets.betsSlip;
      const mType = props.properties['data-mtype'];

      if (!mType) {
        return {};
      }

      if (mType === 'live' && betslip.tickets[betslip.currentTicket]?.live.selected) {
        return {
          deleted_bet: findMatchingBet(props.properties, betslip.tickets[betslip.currentTicket].live.selected),
        };
      }

      if (mType === 'prematch' && betslip.tickets[betslip.currentTicket]?.prematch.selected) {
        return {
          deleted_bet: findMatchingBet(props.properties, betslip.tickets[betslip.currentTicket].prematch.selected),
        };
      }
    },
  },
};

export const logActionEvent = (action_name: string, props: any, context: any, event: any) => {
  try {
    const handler = CAROUSEL_TYPE_HANDLERS[action_name];
    if (!handler) {
      console.debug(`Skipping logging for unhandled action type: ${action_name}`);
      return;
    }

    logEvent(
      event,
      {
        feature_used: action_name,
        feature_category: handler.category(props),
        feature_subcategory: handler.subcategory(props),
      },
      handler.getPayload(props, context),
    );
  } catch (error) {
    console.error('Error logging action event:', error);
  }
};
