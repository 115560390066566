import React from 'react';
import { useMatches, useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../store';

import { appToggleWinnerFun } from '../../modules/bets/store/actions/app';
import { betsSlipClear } from '../../modules/bets/store/actions/betsSlip';
import { setRouterHistory } from '../../modules/casino/store/actions/application';
import evBusMain from '../../utils/evbus';
import { logVisitEvent } from '@/analytics';

const RouteChangeHandler = () => {
  const uriMatches = useMatches();
  const isWinnerFun = useAppSelector((state) => state.bets.app.isWinnerFun);
  const profileState = useAppSelector((state) => state.profile);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [routeState, setRouteState] = React.useState({
    pages: 0,
    currentPath: '',
    pagesHistory: [],
  });

  const handleAction = (data: any, event: string) => {
    //console.log('RouteChangeHandler handleAction', data, event);
    navigate(data.url);
  };

  React.useEffect(() => {
    evBusMain.on('route-change', handleAction);

    return () => {
      evBusMain.remove('route-change', handleAction);
    };
  }, [navigate]);

  React.useEffect(() => {
    if (uriMatches && uriMatches.length) {
      const uriMatch = uriMatches[uriMatches.length - 1];
      const pathname = uriMatch.pathname + window.location.hash;

      if (pathname?.indexOf('/winnerfun/') === 0 && !isWinnerFun) {
        dispatch(appToggleWinnerFun(true));
        dispatch(betsSlipClear());
      } else if (pathname?.indexOf('/winnerfun/') !== 0 && isWinnerFun) {
        dispatch(appToggleWinnerFun(false));
        dispatch(betsSlipClear());
      }

      setRouteState((prev: any) => {
        if (prev.currentPath !== pathname) {
          const newState = {
            pages: prev.pages + 1,
            currentPath: pathname,
            pagesHistory: [pathname, ...prev.pagesHistory].slice(0, 50),
          };
          return newState;
        }
        return prev;
      });
    }
  }, [uriMatches, isWinnerFun]);

  React.useEffect(() => {
    dispatch(setRouterHistory(routeState));

    logVisitEvent(event, routeState);
  }, [routeState]);

  return null;
};

export default RouteChangeHandler;
