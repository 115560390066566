import React from 'react';

import type { SportType, CategoryType, TournamentType, MatchType, StateBetType, BetslipBetType } from './types';
import { isEqual } from 'lodash-es';
import { shallowEqual } from 'react-redux';

import { formatTotalOddsValue } from './functions';
import { formatCurrency, formatAmount2 } from '@/modules/bets/utils/formatters';
import { useAppSelector, useAppDispatch } from '@/store';
import { prematchFetchMatches } from '@/modules/bets/store/actions/prematch';

interface GetDataProps {
  type: 'prematch' | 'live';
}

type SelectedBet = {
  idMbo: string;
};
interface GetDataReturn {
  filterByDate: string | number;
  filterByRange: string;
  sports: {
    [key: string]: SportType;
  };
  categories: {
    [key: string]: CategoryType;
  };
  tournaments: {
    [key: string]: TournamentType;
  };
  bets: {
    [idSport: string]: {
      [idBet: string]: StateBetType;
    };
  };
  matches: {
    [idSport: string]: {
      [idCategory: string]: {
        [idTournament: string]: {
          [idMatch: string]: MatchType;
        };
      };
    };
  };
  loadingState: any;
  selectedBets: SelectedBet[];
  betDesc: {
    [idBet: string]: any;
  };
  fullStateLoaded: boolean;
  matchesList: {
    [idMatch: string]: MatchType;
  };
  inPageIdMatch: string;
  isWinnerFun: boolean;
}

export const getData = ({ type }: GetDataProps): GetDataReturn => {
  const bets = useAppSelector((state) => state.bets[type].bets);
  const categories = useAppSelector((state) => state.bets[type].categories);
  const tournaments = useAppSelector((state) => state.bets[type].tournaments);
  const sports = useAppSelector((state) => state.bets[type].sports);
  const matches = useAppSelector((state) => state.bets[type].data);
  const matchesList = useAppSelector((state) => state.bets[type].matches);
  const filterBy = useAppSelector((state: any) => state.bets.calendar.filterBy);
  const range = useAppSelector((state: any) => state.bets.calendar.range);
  // @ts-ignore
  const loadingState = useAppSelector((state) => state.bets[type].loadingState);
  const selectedBets = useAppSelector(
    (state) => state.bets.betsSlip.tickets[state.bets.betsSlip.currentTicket][type].selected,
  );
  const betDesc = useAppSelector((state) => state.bets.config.betDesc[type]);
  const fullStateLoaded = useAppSelector((state) => state.bets[type].fullStateLoaded);
  const inPageIdMatch = useAppSelector((state) => state.bets.app.inPageIdMatch);
  const isWinnerFun = useAppSelector((state) => state.bets.app.isWinnerFun);

  const data = React.useMemo(() => {
    return {
      bets,
      categories,
      tournaments,
      sports,
      matches,
      matchesList,
      filterByDate: filterBy,
      filterByRange: range,
      loadingState,
      selectedBets,
      betDesc,
      fullStateLoaded,
      inPageIdMatch,
      isWinnerFun,
    };
  }, [
    bets,
    categories,
    tournaments,
    sports,
    matches,
    matchesList,
    filterBy,
    loadingState,
    selectedBets,
    betDesc,
    fullStateLoaded,
    inPageIdMatch,
    isWinnerFun,
  ]);

  return data;
};

interface GetMatchDataProps {
  mType: 'prematch' | 'live';
  idSport: string;
  idCategory: string;
  idTournament: string;
  idMatch: string;
}

interface GetMatchDataReturn {
  sport: SportType;
  category: CategoryType;
  tournament: TournamentType;
  match: MatchType;
  selectedBets: SelectedBet[];
  inPageIdMatch: string;
  loadingState: boolean;
  fullStateLoaded: boolean;
}

export const getMatchData = ({
  mType,
  idSport,
  idCategory,
  idTournament,
  idMatch,
}: GetMatchDataProps): GetMatchDataReturn => {
  // @ts-ignore
  const loadingState = useAppSelector((state) => state.bets[mType].loadingState);
  const fullStateLoaded = useAppSelector((state) => state.bets[mType].fullStateLoaded);
  const sports: any = useAppSelector((state) => state.bets[mType].sports);
  const categories: any = useAppSelector((state) => state.bets[mType].categories);
  const tournaments: any = useAppSelector((state) => state.bets[mType].tournaments);
  const matches: any = useAppSelector((state) => state.bets[mType].data);
  const inPageIdMatch: any = useAppSelector((state) => state.bets.app.inPageIdMatch);
  const selectedBets = useAppSelector(
    (state) => state.bets.betsSlip.tickets[state.bets.betsSlip.currentTicket][mType].selected,
  );
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (
      idSport &&
      idCategory &&
      idTournament &&
      idMatch &&
      matches[idSport]?.[idCategory]?.[idTournament]?.[idMatch] == null
    ) {
      dispatch(prematchFetchMatches([idMatch]));
    }
  }, []);

  const data: GetMatchDataReturn = React.useMemo(() => {
    let sport: any = null;
    let category: any = null;
    let tournament: any = null;
    let match: any = null;

    if (idSport && sports[idSport] != null && sports[idSport].sportActive) {
      sport = sports[idSport];
      if (idCategory && categories[idCategory] != null && categories[idCategory].categoryActive) {
        category = categories[idCategory];
        if (idTournament && tournaments[idTournament] != null && tournaments[idTournament].tournamentActive) {
          tournament = tournaments[idTournament];
          if (idMatch && matches[idSport]?.[idCategory]?.[idTournament]?.[idMatch] != null) {
            match = matches[idSport][idCategory][idTournament][idMatch];
          }
        }
      }
    }

    return {
      sport,
      category,
      tournament,
      match,
      selectedBets,
      inPageIdMatch,
      loadingState,
      fullStateLoaded,
    };
  }, [sports, categories, tournaments, matches, selectedBets, inPageIdMatch, loadingState, fullStateLoaded]);

  return data;
};

interface GetBetslipDataReturn {
  authentication: {
    access_token: string;
    auth_type: string;
  };
  ticketOnline: boolean;
  ticketType: string;
  systems: any[];
  liveBets: BetslipBetType[];
  prematchBets: BetslipBetType[];
  selectError: any;
  errorMessage: string;
  prematchMatches: {
    [idMatch: string]: MatchType;
  };
  liveMatches: {
    [idMatch: string]: MatchType;
  };
  amount: number;
  tax: number;
  taxPercent: number;
  winAmount: number;
  minWinAmount: number;
  totalOdds: number;
  autoAcceptOddChange: boolean;
  allowTicketPlaceWithInvalidEvents: boolean;
  placeTicketEnabled: boolean;
  placeTicketErrorMessage: string;
  wallet: {
    bonuses?: any[];
    main?: number;
    bonus?: number;
    total?: number;
    ringFence?: number;
    currency?: string;
  };
  profile: any;
  walletBonuses: any[];
  bonus: {
    ticketAppliedBonus: any;
    ticketBonusEligibles: any[];
    digitainAppliedBonus: any;
  };
  bonusEvaluate: any;
  winnerFunEvaluate: any;
  winnerFunBoostNum: number;
  winnerFunBoostActive: boolean;
  isWinnerFun: boolean;

  freeBets: any[];
  selectedFreeBet: number;
  selectedFreeBetSubIndex: number;
  freeBetsEvaluate: any;

  tournamentEvaluate: any;
}

const emptyObject: any = {};
const emptyArray: any[] = [];

export const getBetslipData = (): GetBetslipDataReturn => {
  const data = useAppSelector((state) => {
    const bst = state.bets;
    const ct = bst.betsSlip.tickets[bst.betsSlip.currentTicket];

    const prematchMS: { [idMatch: string]: MatchType } = {};
    ct.prematch.selected.forEach((b: any) => {
      if (b.idMatch in bst.prematch.matches && !(b.idMatch in prematchMS)) {
        // @ts-ignore
        prematchMS[b.idMatch] = { ...bst.prematch.matches[b.idMatch] };
      }
    });

    const liveMS: { [idMatch: string]: MatchType } = {};
    ct.live.selected.forEach((b: any) => {
      if (b.idMatch in bst.live.matches && !(b.idMatch in liveMS)) {
        // @ts-ignore
        liveMS[b.idMatch] = { ...bst.live.matches[b.idMatch] };
      }
    });

    let winnerFunBoostNum = 0;
    const isWinnerFun = bst.app.isWinnerFun;
    if (isWinnerFun) {
      const b = state.winnerFun.winCoinsSystems.data.find((s: any) => s.type === 'boost');
      winnerFunBoostNum = b ? b.boost : 0;
    }

    let walletBonuses = null;
    if (!isWinnerFun) {
      walletBonuses =
        window.config.useBonusEvaluation === '1' && state.wallet && state.wallet.bonuses
          ? state.wallet.bonuses
          : emptyArray;
    }

    return {
      authentication: state.authentication,

      ticketOnline: ct.ticketOnline,
      ticketType: ct.ticketType,
      systems: ct.systems,

      liveBets: ct.live.selected,
      prematchBets: ct.prematch.selected,

      selectError: bst.betsSlip.selectError,
      errorMessage: ct.errorMessage,

      prematchMatches: Object.keys(prematchMS).length === 0 ? emptyObject : prematchMS,
      liveMatches: Object.keys(liveMS).length === 0 ? emptyObject : liveMS,

      amount: ct.amount,
      tax: ct.tax,
      taxPercent: ct.taxPercent,
      winAmount: ct.totalMaxWinAmount,
      minWinAmount: ct.totalMinWinAmount,
      totalOdds: ct.totalOdds,
      autoAcceptOddChange: ct.autoAcceptOddChange,
      allowTicketPlaceWithInvalidEvents: ct.allowTicketPlaceWithInvalidEvents,
      placeTicketEnabled: ct.placeTicketEnabled,
      placeTicketErrorMessage: ct.placeTicketErrorMessage,

      wallet: isWinnerFun ? state.winnerFun.wallet : state.wallet,
      profile: state.profile,

      walletBonuses: walletBonuses != null ? walletBonuses : emptyArray,

      bonus: ct.bonus,
      bonusEvaluate: ct.bonusEvaluate,
      winnerFunEvaluate: ct.winnerFunEvaluate,
      winnerFunBoostNum: winnerFunBoostNum,
      winnerFunBoostActive: ct.useWinnerFunBoost,

      isWinnerFun: bst.app.isWinnerFun,

      freeBets: state.freeBets.freeBets,
      selectedFreeBet: bst.betsSlip.selectedFreeBet,
      selectedFreeBetSubIndex: bst.betsSlip.selectedFreeBetSubIndex,
      freeBetsEvaluate: ct.freeBetsEvaluate,

      tournamentEvaluate: ct.tournamentEvaluate,
    };
  }, isEqual);

  const [state, setState] = React.useState(data);

  React.useEffect(() => {
    const equal = isEqual(data, state);

    if (!equal) {
      setState(data);
    }
  }, [data, state]);

  return state;
};

interface GetMultiBetDataReturn {
  walletCurrency: string;
  numBets: number;
  amount: number;
  totalOdds: string;
  winAmount: string;
}

export const getMultiBetData = (): GetMultiBetDataReturn => {
  const currency = useAppSelector((state) => state.wallet.currency);
  const isWinnerFun = useAppSelector((state) => state.bets.app.isWinnerFun);
  const currentTicket = useAppSelector((state) => {
    const bst = state.bets;
    return bst.betsSlip.tickets[bst.betsSlip.currentTicket];
  });

  const dayMultiBets = useAppSelector((state) => state.bets.config.dayMultiBets);

  const state = React.useMemo(() => {
    let overrideTotalOdds = null;
    let overrideWinning = null;

    const dayMultiBetNumber = currentTicket.dayMultiBetNumber;

    if (
      !isWinnerFun &&
      dayMultiBetNumber > 0 &&
      dayMultiBets &&
      dayMultiBets.length &&
      currentTicket.ticketType === 'single'
    ) {
      const dayMultiBet: any = dayMultiBets.find((dmb: any) => dmb.number === dayMultiBetNumber);

      if (dayMultiBet && dayMultiBet.express_factor) {
        overrideTotalOdds = currentTicket.totalOdds * dayMultiBet.express_factor;
        overrideWinning = currentTicket.totalMaxWinAmount * dayMultiBet.express_factor;
      }
    }

    return {
      walletCurrency: isWinnerFun ? 'W' : formatCurrency(currency),
      numBets: currentTicket.live.selected.length + currentTicket.prematch.selected.length,
      amount: currentTicket.amount,
      totalOdds: formatTotalOddsValue(currentTicket.totalOdds, false),
      winAmount: formatAmount2(currentTicket.totalMaxWinAmount),

      overrideTotalOdds,
      overrideTotalOddsStr: overrideTotalOdds ? formatTotalOddsValue(overrideTotalOdds, false) : '',
      overrideWinning,
      overrideWinningStr: overrideWinning ? formatAmount2(overrideWinning) : '',
    };
  }, [isWinnerFun, currentTicket, currency, dayMultiBets]);

  return state;
};

export const GetPrematchCalendarData = (): any => {
  const data = useAppSelector(
    (state: any) => ({
      sportsMeta: state.bets.prematch.sportsMeta,
      categoriesMeta: state.bets.prematch.categoriesMeta,
      tournamentsMeta: state.bets.prematch.tournamentsMeta,
      matches: state.bets.prematch.matches,
      filterByDate: state.bets.calendar.filterBy,
      filterByRange: state.bets.calendar.range,
    }),
    shallowEqual,
  );

  /*
  const [state, setState] = React.useState(data);

  React.useEffect(() => {
    //console.time('NavigatorDesktop[GetPrematchCalendarData]');

    let changed = false;

    if (data.filterByDate !== state.filterByDate || Object.keys(state.sportsMeta).length === 0) {
      changed = true;
    } else if (
      Object.keys(data.sportsMeta).length !== Object.keys(state.sportsMeta).length ||
      JSON.stringify(data.sportsMeta) !== JSON.stringify(state.sportsMeta)
    ) {
      changed = true;
    } else if (
      Object.keys(data.categoriesMeta).length !== Object.keys(state.categoriesMeta).length ||
      JSON.stringify(data.categoriesMeta) !== JSON.stringify(state.categoriesMeta)
    ) {
      changed = true;
    } else if (
      Object.keys(data.tournamentsMeta).length !== Object.keys(state.tournamentsMeta).length ||
      JSON.stringify(data.tournamentsMeta) !== JSON.stringify(state.tournamentsMeta)
    ) {
      changed = true;
    }

    if (changed) setState(data);
    //console.timeEnd('NavigatorDesktop[GetPrematchCalendarData]');
  }, [data, state]);
  */

  return data;
};
