import { BetslipBetType, MatchType } from '@/components/modules/bets/utils/types';
import { betsRootReducer } from '@/modules/bets/store/reducers';
import { RecommendedBetsState } from '.';

interface Motivation {
  text: Record<string, string>;
}

export interface RecommendedMatchType extends MatchType {
  brmId: string;
  brmIdMb: string;
  brmIdMbo: string;
  brmMotivations: Motivation[];
  brmNumbers: string;
  brmPeriodIdMatch: string;
}

const languages: Record<string, string> = {
  en: '2',
  ro: '42',
};

const FORMAT_PATTERN = /{([^}]+)}/g;

function replaceParams(
  text: string,
  data: Record<string, any>,
  languageId: string,
  replacePattern = FORMAT_PATTERN,
): string {
  return text.replace(replacePattern, (placeholder, param) => data[param][languages[languageId]] ?? '');
}

export function formatMotivations(match: RecommendedMatchType): RecommendedMatchType {
  const brmMotivations: Motivation[] = [];

  match.brmMotivations.forEach((brmMotivation) => {
    const motivation: Motivation = {
      text: {},
    };

    Object.keys(brmMotivation.text).forEach((language) => {
      motivation.text[language] = replaceParams(brmMotivation.text[language], match, language);
    });

    brmMotivations.push(motivation);
  });

  return {
    ...match,
    brmMotivations,
  };
}

export function isMatchAdded(
  betsSlip: ReturnType<typeof betsRootReducer>['betsSlip'],
  idMatch: string,
  idMbo: string,
): boolean {
  const currentTicket = betsSlip.tickets[betsSlip.currentTicket];

  const isMatchAdded = [...currentTicket.live.selected, ...currentTicket.prematch.selected].find(
    (bet: BetslipBetType) => bet.idMatch === idMatch && bet.idMbo === idMbo,
  );

  return !!isMatchAdded;
}

export function crossListsFilter(match: RecommendedMatchType): (match: RecommendedMatchType) => boolean {
  return ({ idMatch, brmIdMbo }: RecommendedMatchType) => idMatch === match.idMatch && brmIdMbo === match.brmIdMbo;
}

export function getBetSlipList(
  betsSlip: ReturnType<typeof betsRootReducer>['betsSlip'],
  originalList: RecommendedMatchType[],
): RecommendedMatchType[] {
  return originalList.filter((match) => isMatchAdded(betsSlip, match.idMatch, match.brmIdMbo));
}

export function getFullList(
  originalList: RecommendedMatchType[],
  betsSlipList: RecommendedMatchType[],
  newList: RecommendedMatchType[] = [],
): RecommendedMatchType[] {
  const filtered = originalList.filter((match) => !betsSlipList.find(crossListsFilter(match)));

  return newList.length ? [...filtered, ...newList] : filtered;
}

export function getCommonStateSlice(
  prevState: RecommendedBetsState,
  newList: RecommendedMatchType[],
  pageSize: number,
): RecommendedBetsState {
  const lastPage = newList.length ? Math.ceil(newList.length / pageSize) - 1 : 0;
  const nextPage = lastPage < prevState.currentPage ? 0 : prevState.currentPage;
  const pageStartIndex = nextPage * pageSize;

  return {
    ...prevState,
    currentPage: nextPage,
    lastPage,
    currentList: newList.slice(pageStartIndex, pageStartIndex + pageSize),
    displayCount: `(${newList.length})`,
    showLoader: newList.length > 1 && newList.length > pageSize,
  };
}
