import React from 'react';
import { useAppSelector } from '../../store';
import { uniqueId } from 'lodash-es';

import craftJsParser from '../../components/utils/craftJsParser';
import transferPropertiesToComponent from '../ComponentData/transferPropertiesToComponent';
import { DataElementContext } from '../common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { useMediaQuery } from '@/components/utils/useQueryMedia';

export const defaultProps = {
  properties: {
    componentId: null,
  },
  visibility: {},
};

export const ComponentRef = (componentProps) => {
  let props = componentProps;

  const [__id] = React.useState(uniqueId('unique-'));
  const components = useAppSelector((state) => state.templatesConfig.components);
  const setComponentsDefaults = useAppSelector((state) => state.templatesConfig.setComponentsDefaults);
  const authenticated = useAppSelector((state) => state.authentication && state.authentication.auth_type === 'user');

  const dataElementContext = React.useContext(DataElementContext);
  let isVisible = true;

  const isTablet = useMediaQuery(`(min-width: ${window.config.breakpointTablet})`);
  const isDesktop = useMediaQuery(`(min-width: ${window.config.breakpointDesktop})`);
  const breakpoints = React.useMemo(() => ({ isTablet, isDesktop }), [isTablet, isDesktop]);

  [props, isVisible] = processComponentProps(props, dataElementContext, breakpoints);

  const { componentId } = props.properties;

  let content = React.useMemo(() => {
    try {
      const projectData = components[componentId].projectData;

      const setComponentsDefaultsValue =
        setComponentsDefaults[componentId] != null ? setComponentsDefaults[componentId] : {};

      const data = JSON.parse(projectData);

      if (setComponentsDefaultsValue && Object.keys(setComponentsDefaultsValue).length)
        transferPropertiesToComponent(data, setComponentsDefaultsValue);

      let rootNodeId = 'ROOT';

      if (rootNodeId) {
        data[rootNodeId].type = 'Fragment';
        data[rootNodeId].props = {
          'className': componentProps.className ?? '',
          'properties': {},
        };

        if (data[rootNodeId].nodes && data[rootNodeId].nodes.length === 1) {
          rootNodeId = data[rootNodeId].nodes[0];
        }

        if (window !== window.parent && data?.[rootNodeId] && componentProps?.id) {
          data[rootNodeId].id = componentProps.id;
          data[componentProps.id] = data[rootNodeId];
          rootNodeId = componentProps.id;
        }

        return craftJsParser({
          craftState: data,
          rootNodeId: rootNodeId,
          pageId: componentId,
          pageType: 'components',
          options: {
            authenticated,
          },
          withProvider: true,
          children: componentProps.children ?? null,
        });
      }
    } catch (err) {
      console.error('ComponentRef', err);
    }
    return null;
  }, [components, setComponentsDefaults, componentId, authenticated]);

  const contextValue = React.useMemo(() => {
    const skipProps = ['componentId', 'dsType', 'propFunction', 'links', 'megaComponent'];
    let contextValue = {};

    props?.properties && Object.keys(props.properties).forEach(key => {
      if (skipProps.indexOf(key) > -1) return;
      contextValue[key] = props.properties[key];
    });

    contextValue = {
      ...dataElementContext,
      ...contextValue,
      __id,
    };
    return contextValue;
  }, [componentProps, dataElementContext, components, setComponentsDefaults, authenticated]);

  if (!isVisible) return null;

  /*
  if (props?.properties?.componentId === '915417713728487426') {
    console.log('ComponentRef[properties]', props.properties);
    console.log('ComponentRef[dataElementContext]', dataElementContext);
    console.log('ComponentRef[contextValue]', contextValue);
  };
  */

  return <DataElementContext.Provider value={contextValue}>{content}</DataElementContext.Provider>;
};
