import { endpoints } from '@/api/endpoints';
import { EventData } from '../types/eventData';
import store from '@/store';

/**
 * Configuration for tracking attempts
 */
const CONFIG = {
  TIMEOUT: 5000, // 5 seconds timeout for each attempt
  DETECTOR_TIMEOUT: 1000, // 1 second timeout for adblocker detection
};

/**
 * Cache for adblocker detection result to avoid repeated checks
 */
let isAdBlockerDetected: boolean | null = null;

/**
 * Detects if an adblocker is present using multiple methods
 */
const detectAdBlocker = async (): Promise<boolean> => {
  if (isAdBlockerDetected !== null) {
    return isAdBlockerDetected;
  }

  try {
    // render a bait div and check if it's blocked
    const detectBait = async (): Promise<boolean> => {
      return new Promise((resolve) => {
        const baitDiv = document.createElement('div');
        baitDiv.setAttribute('class', 'ads ad adsbox doubleclick pub-123');
        baitDiv.setAttribute('style', 'height: 1px; width: 1px; position: absolute; left: -999px; top: -999px;');

        const timeoutId = setTimeout(() => {
          document.body.removeChild(baitDiv);
          resolve(true); // Bait was blocked or timeout reached
        }, CONFIG.DETECTOR_TIMEOUT);

        document.body.appendChild(baitDiv);

        // Check if the bait was blocked
        requestAnimationFrame(() => {
          const blocked =
            baitDiv.offsetHeight === 0 ||
            baitDiv.offsetParent === null ||
            window.getComputedStyle(baitDiv).display === 'none';

          clearTimeout(timeoutId);
          document.body.removeChild(baitDiv);
          resolve(blocked);
        });
      });
    };

    // Method 2: Try to fetch a test pixel
    const detectWithPixel = async (): Promise<boolean> => {
      return new Promise((resolve) => {
        const img = new Image();
        const timeoutId = setTimeout(() => resolve(true), CONFIG.DETECTOR_TIMEOUT);

        img.onload = () => {
          clearTimeout(timeoutId);
          resolve(false);
        };

        img.onerror = () => {
          clearTimeout(timeoutId);
          resolve(true);
        };

        // Try to load a typical ad pixel URL
        // no endpoint for pixel for the moment
        // img.src = `${endpoints.analytics.pixel}?ch=${Math.random()}`;
      });
    };

    // Run both detection methods and consider an adblocker present if either method detects it
    const [baitBlocked, pixelBlocked] = await Promise.all([detectBait(), detectWithPixel()]);

    isAdBlockerDetected = baitBlocked || pixelBlocked;
    return isAdBlockerDetected;
  } catch {
    // If fails, adblocker present
    isAdBlockerDetected = true;
    return true;
  }
};

/**
 * Attempts to send data using the Beacon API
 */
const sendWithBeacon = (url: string, data: EventData): boolean => {
  try {
    const queued = navigator.sendBeacon(url, JSON.stringify(data));
    if (queued) {
      console.log('Data queued with Beacon API, but sending not guaranteed');
      return true;
    }
    return false;
  } catch {
    return false;
  }
};

/**
 * Attempts to send data using fetch with no-cors mode
 */
const sendWithFetch = async (url: string, data: EventData): Promise<boolean> => {
  try {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), CONFIG.TIMEOUT);

    const access_token = store.getState().authentication.access_token;
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + access_token,
      },
      body: JSON.stringify(data),
      keepalive: true,
      signal: controller.signal,
    });

    clearTimeout(timeoutId);
    return true;
  } catch (error) {
    console.error('Error:', error);
    return false;
  }
};

/**
 * Attempts to send data using an image GET request
 */
const sendWithImage = (url: string, data: EventData): Promise<boolean> => {
  return new Promise((resolve) => {
    const timeoutId = setTimeout(() => resolve(false), CONFIG.TIMEOUT);

    // Convert data to base64 to avoid URL length limits
    const serializedData = btoa(JSON.stringify(data));
    const getUrl = `${url}?d=${serializedData}&t=${Date.now()}`;
    const img = new Image();

    img.onload = () => {
      clearTimeout(timeoutId);
      resolve(true);
    };

    img.onerror = () => {
      clearTimeout(timeoutId);
      resolve(false);
    };

    img.src = getUrl;
  });
};

/**
 * Sends event data to the analytics backend using various fallback methods.
 * Adapts strategy based on adblocker presence.
 * Never throws errors and always resolves to a boolean.
 */
export const trackEvent = async (eventData: EventData): Promise<boolean> => {
  try {
    const analyticsEndpoint = endpoints.analytics.event;

    // requires a lot of tests
    // const hasAdBlocker = await detectAdBlocker();
    // const hasAdBlocker = false;

    // micros endpoint needs authentication,
    // so we will use fetch for the moment
    const hasAdBlocker = true;

    if (!hasAdBlocker) {
      // No adblocker - try standard approach
      if (sendWithBeacon(analyticsEndpoint, eventData)) {
        return true;
      }

      if (await sendWithFetch(analyticsEndpoint, eventData)) {
        return true;
      }
    } else {
      // Adblocker detected - skip likely-to-fail methods
      console.debug('Adblocker detected, using alternative tracking methods');

      // Try fetch first with minimal headers
      const fetchSuccess = await sendWithFetch(
        // Use a non-analytics-looking endpoint if configured
        // endpoints.analytics.alternate || analyticsEndpoint,
        analyticsEndpoint,
        eventData,
      );

      if (fetchSuccess) return true;
    }

    // Final fallback - try image request
    // Some adblockers might still block this, but worth trying
    // return await sendWithImage(endpoints.analytics.alternate || analyticsEndpoint, eventData);
    // return await sendWithImage(analyticsEndpoint, eventData);

    return false;
  } catch {
    console.warn('Unexpected error in trackEvent');
    return false;
  }
};

/**
 * Utility function to check if an adblocker is present.
 * Results are cached for performance.
 */
export const checkForAdBlocker = async (): Promise<boolean> => {
  return detectAdBlocker();
};