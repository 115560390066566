import { FeatureCategory, FeatureSubcategory } from '../types/eventData';
import { logEvent } from './logEvent';

interface CarouselTypeConfig {
  category: (properties?: string) => FeatureCategory;
  subcategory: (properties?: any) => FeatureSubcategory;
  getPayload: (data: any) => any;
}

const CAROUSEL_TYPE_HANDLERS: Record<string, CarouselTypeConfig> = {
  banner: {
    category: () => FeatureCategory.Promo,
    subcategory: (properties) => {
      if (!properties) {
        return FeatureSubcategory.LpPromoCarousel;
      } else {
        if (properties.id && properties.id.includes('story')) {
          return FeatureSubcategory.LpStories;
        }
        return FeatureSubcategory.LpPromoCarousel;
      }
    },
    getPayload: (data) => ({
      BannerContent: {
        Title: data.message_primary_short,
        Subtitle: data.message_secondary_long,
        SlideDetails: data,
      },
    }),
  },
  stories: {
    category: () => FeatureCategory.Promo,
    subcategory: () => FeatureSubcategory.LpStories,
    getPayload: (data) => ({
      StoryContent: {
        Title: data.message_primary_short,
        Subtitle: data.message_secondary_long,
        SlideDetails: data,
      },
    }),
  },
  'bets.data.tournament': {
    category: () => FeatureCategory.Unspecified,
    subcategory: () => FeatureSubcategory.Unspecified,
    getPayload: (data) => ({
      TournamentContent: {
        WholeData: data,
      },
    }),
  },
  slot_game: {
    category: () => FeatureCategory.Unspecified,
    subcategory: () => FeatureSubcategory.Unspecified,
    getPayload: (data) => ({
      SlotGameContent: {
        WholeData: data,
      },
    }),
  },
};

export const logCarouselEvent = (
  event: Event | MouseEvent | TouchEvent | KeyboardEvent | null,
  event_type: string,
  data_source: any[],
  data_source_id: string,
  dataElementTypeId: string,
  previousIndex: number,
  properties?: any,
): void => {
  try {
    const handler = CAROUSEL_TYPE_HANDLERS[dataElementTypeId];
    if (!handler) {
      console.debug(`Skipping logging for unhandled carousel type: ${dataElementTypeId}`);
      return;
    }

    logEvent(
      event,
      {
        feature_used: `${dataElementTypeId}_${data_source_id}_${event_type}`,
        feature_category: handler.category(properties),
        feature_subcategory: handler.subcategory(properties),
      },
      handler.getPayload(data_source[previousIndex]),
    );
  } catch (error) {
    console.error('Error logging carousel event:', error);
  }
};